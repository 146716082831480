import {
  Datagrid,
  List,
  TextField,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  EditButton,
  required,
} from "react-admin";

export const preguntasFrecuentesList = () => (
  <List title={"Preguntas frecuentes"}>
    <Datagrid>
      <TextField source="id" label="ID" />
      <TextField source="pregunta" />
      <TextField source="respuesta" />
      <TextField source="cat" label="Categoría" />
      <EditButton />
    </Datagrid>
  </List>
);

const form = (
  <SimpleForm>
    <TextInput multiline source="pregunta" validate={[required]} />
    <TextInput multiline source="respuesta" validate={[required]} />
    <TextInput multiline source="cat" validate={[required]} />
  </SimpleForm>
);

export const preguntasFrecuentesCreate = () => <Create>{form}</Create>;

export const preguntasFrecuentesEdit = () => <Edit>{form}</Edit>;
