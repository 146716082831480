import React, { Component } from "react";
import { useParams, useNavigate } from "react-router-dom";
import calcularPx from "../general/calcularPx";

function withRouterAndNavigate(Component) {
  function ComponentWithRouterAndNavigate(props) {
    let params = useParams();
    let navigate = useNavigate();
    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouterAndNavigate;
}

class Cambiar extends Component {
  state = {
    passValida: false,
    passConfirmada: false,
    mensaje: null,
    error: null,
  };

  async cambiarPass() {
    const res = await fetch("/api/cuentas/cambiarPass", {
      method: "post",
      body: JSON.stringify({
        token: this.props.params.token,
        pass: this.state.pass,
      }),
      headers: { "Content-Type": "application/json" },
    });
    const data = await res.json();

    if (data.exito) {
      this.setState(
        {
          error: false,
          passConfirmada: false,
          mensaje: "Contraseña cambiada. Redirigiendo a la página principal...",
        },
        () => {
          setTimeout(() => {
            this.props.navigate("/");
          }, 3000);
        }
      );
    } else {
      this.setState(
        {
          error: true,
          passConfirmada: false,
          mensaje:
            "El link expiró. Obtenga uno nuevo en Ingresar --- Olvidé mi contraseña",
        },
        () => {
          setTimeout(() => {
            this.props.navigate("/");
          }, 4000);
        }
      );
    }
  }

  render() {
    const { error, mensaje } = this.state;
    return (
      <div
        style={{
          position: "fixed",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          zIndex: 101,
        }}
      >
        <div
          className="ingreso_recuadro"
          style={{marginLeft: "auto", marginRight: "auto", marginTop: "3vw"}}
        >
          <div className="ingreso_recuadro_titulo">
              Cambiar contraseña
          </div>
          <input
              placeholder="Contraseña"
              className="ingreso_campo"
              type="password"
              onChange={(event) => {
                let pass = event.target.value;
                let valida =
                  0 < pass.length && pass.length < 16 && !pass.includes(" ");
                if (valida) {
                  event.target.style.borderColor = "#cccbcb";
                } else {
                  event.target.style.borderColor = "#9d1c21";
                }
                document.getElementById("pass2").value = "";
                this.setState({
                  pass: event.target.value,
                  passValida: valida,
                  passConfirmada: false,
                  error: null,
                });
              }}
              onKeyUp={(event) => {
                if (event.key === "Enter") {
                  document.getElementById("botonCambiar").click();
                }
              }}
            />

            <input
              id="pass2"
              placeholder="Repetir contraseña"
              className="ingreso_campo"
              type="password"
              onChange={(event) => {
                let confirmada = event.target.value === this.state.pass;
                if (confirmada) {
                  event.target.style.borderColor = "#cccbcb";
                } else {
                  event.target.style.borderColor = "#9d1c21";
                }
                this.setState({
                  passConfirmada: event.target.value === this.state.pass,
                  error: null,
                });
              }}
              onKeyUp={(event) => {
                if (event.key === "Enter") {
                  document.getElementById("botonCambiar").click();
                }
              }}
            />

          <button
            id="botonCambiar"
            className="ingreso_boton"
            disabled={!this.state.passConfirmada}
            onClick={() => this.cambiarPass()}
          >
            Cambiar contraseña
          </button>
          {mensaje && (
            <div
              className="ingreso_mensaje"
            >
              {mensaje}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouterAndNavigate(Cambiar);
