import React, { Component } from "react";
import calcularPx from "../general/calcularPx";
import { Link, useLocation } from "react-router-dom";

function withLocation(Component) {
  function ComponentWithLocation(props) {
    let location = useLocation();
    return <Component {...props} location={location} />;
  }
  return ComponentWithLocation;
}

class Producto extends Component {
  state = { urlImagen: null };

  async componentDidMount() {
    const res = await fetch(this.props.producto.imgPath);
    const blob = await res.blob();
    const url = URL.createObjectURL(blob);
    this.setState({ urlImagen: url });
  }

  componentWillUnmount() {
    URL.revokeObjectURL(this.state.urlImagen);
  }

  render() {
    const { modelo, url, categoria, subtitulo1, subtitulo2 } = this.props.producto;
    return (
      <Link
        to={"/" + categoria + "/" + url}
        className="producto_card"
      >
        <div
          style={{
            width: "100%",
            height: 270,
            backgroundColor: "#f5f6f8"
          }}
        >
          <img
            alt=""
            src={this.state.urlImagen}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </div>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: 106,
            background: "transparent",
          }}
        >
          <h2
            className="producto_texto1"
          >
            {modelo}
          </h2>
          <h3
            className="producto_texto2"
          >
            {subtitulo1}
          </h3>
          <h4
            className="producto_texto3"
          >
            {subtitulo2}
          </h4>
        </div>
      </Link>
    );
  }
}

export default withLocation(Producto);
