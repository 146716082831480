import React, { Component } from 'react';
import { ReactComponent as IcoTick } from "../../res/iconos/tick.svg";

class Gracias extends Component {
    state = {  } 
    render() { 
        return (
        <div style={{width: "80%", maxWidth: 650, marginLeft: "auto", marginRight: "auto", display: "flex", flexDirection: "column", alignContent: "center", alignItems: "center"}}>
            <div style={{width: "100%", height: 180}}/>
            <IcoTick width={121} height={121}/>
            <div style={{width: "100%", height: 40}}/>
            <div className="gracias_titulo">
            ¡Muchas gracias!
            </div>
            {this.props.mensaje1 && 
            <>
            <div style={{width: "100%", height: 30}}/>
            <div className="gracias_mensaje1">
                {this.props.mensaje1}<span className="gracias_mensaje1_rojo">{" correctamente"}</span>
            </div>
            </>
            }
            {this.props.tipo && this.props.nro && 
            <>
            <div style={{width: "100%", height: 30}}/>
            <div style={{display:"flex", flexDirection: "row", justifyContent: "center", justifyItems: "center", gap: 25}}>
                <div className="gracias_tipo">
                    N° del {this.props.tipo}
                </div>
                <div className="gracias_nro">
                    #{this.props.nro}
                </div>
            </div>
            </>
            }
            {this.props.mensaje2 && 
            <>
            <div style={{width: "100%", height: 40}}/>
            <div className="gracias_mensaje2">
                {this.props.mensaje2}
            </div>
            </>
            }
            {this.props.aclaracion && 
            <>
            <div style={{width: "100%", height: 30}}/>
            <div className="gracias_aclaracion">
            *{this.props.aclaracion}
            </div>
            </>
            }
            <div style={{width: "100%", height: "7vw"}}/>
        </div>
        );
    }
}
 
export default Gracias;