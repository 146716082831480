import React, { Component, useEffect } from "react";
import Gracias from "../general/gracias";
import { useLocation } from "react-router-dom";


const PosventaExito = () => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    },[])
    return (<Gracias tipo="caso" nro={location?.state?.nroReclamo} mensaje1="Su solicitud fue registrada" mensaje2="Nuestro equipo posventa validará los datos y derivará el caso a unos de los Service Autorizados." aclaracion="Recuerde revisar la casilla de su correo electrónico."/>
    );
}
 
export default PosventaExito;
