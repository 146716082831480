import React, { Component } from "react";
import calcularPx from "../general/calcularPx";
import { ReactComponent as IcoMas } from "../../res/iconos/masArchivo.svg";
import { ReactComponent as IcoArchivo } from "../../res/iconos/archivo.svg";
import { Navigate } from "react-router-dom";

class CV extends Component {
  state = {
    email: "",
    emailValido: false,
    nombre: "",
    nombreValido: false,
    apellido: "",
    apellidoValido: false,
    telefono: "",
    telefonoValido: false,
    fecha_nacimiento: "",
    fecha_nacimientoValido: false,
    archivoCV: null,
    nombreCV: "Seleccionar archivo",
    error: null,
    enviando: false,
    nroCV: -1,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  fileToDataUrl = (blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });

  fileToBase64 = (blob) => this.fileToDataUrl(blob).then((text) => text);

  async enviar() {
    this.setState({ error: null, enviando: true });
    let archivoCV = await this.fileToBase64(this.state.archivoCV);
    const res = await fetch("/api/rrhh/cargarCV", {
      method: "post",
      body: JSON.stringify({
        email: this.state.email,
        nombre: this.state.nombre,
        apellido: this.state.apellido,
        telefono: this.state.telefono,
        fecha_nacimiento: this.state.fecha_nacimiento,
        archivoCV: archivoCV,
        cvOriginal: this.state.nombreCV,
      }),
      headers: { "Content-Type": "application/json" },
    });
    const data = await res.json();
    if (data.nroCV !== -1) {
      this.setState({ nroCV: data.nroCV, enviando: false });
    } else {
      this.setState({ enviando: false });
    }
  }

  validarMail(email) {
    const emailRegex =
      /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
    if (!email) return false;

    if (email.length > 254) return false;

    let valid = emailRegex.test(email);
    if (!valid) return false;

    var parts = email.split("@");
    if (parts[0].length > 64) return false;

    var domainParts = parts[1].split(".");
    if (
      domainParts.some(function (part) {
        return part.length > 63;
      })
    )
      return false;

    return true;
  }

  render() {
    const mobile = window.innerWidth < 1280;
    let f = new Date();
    const offset = f.getTimezoneOffset();
    f = new Date(f.getTime() - offset * 60 * 1000);
    let fecha = f.toISOString().split("T")[0];
    const styleCampo = {
    };
    return (
      this.state.nroCV == -1 ?
      <React.Fragment>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: 300,
          }}
        >
          <img
            src={require("../../res/banners/rrhh_" + (mobile ? "mobile":"desktop" ) + ".jpg")}
            style={{
              position: "relative",
              objectFit: "cover",
              objectPosition: "center",
              width: "100%",
              height: 300,
              zIndex: -1,
            }}
          />
          <img
            src={require("../../res/banners/rrhh_titulo.png")}
            style={{
              position: "absolute",
              objectFit: "cover",
              objectPosition: "left",
              width: "80%",
              maxWidth: 466,
              height: "auto",
              top: 113,
              left: "10%",
              zIndex: 0,
            }}
          />
        </div>
        <h1 style={{position: "absolute", width: 1, height: 1, opacity: 0, overflow: "hidden"}}>Recursos humanos</h1>

        <div style={{ width: "100%", height: "2vw" }} />
        <h2 className="cv_titulo">Información personal</h2>
        <div style={{width: "80%", marginLeft: "auto", marginRight: "auto", display: "flex", justifyContent: "space-between", justifyItems: "space-between", alignContent: "stretch", gap: 50, flexWrap: "wrap"}}>
            <div style={{display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between", gap: 24}}>
              <div className="cv_campo_titulo">
                Nombre y Apellido
                <input
                  placeholder="Por favor ingrese su nombre y apellido"
                    className="cv_campo"
                    type="text"
                    style={styleCampo}
                    onChange={(event) => {
                      let valido =
                        0 < event.target.value.length &&
                        event.target.value.length <= 20;
                      if (valido) {
                        event.target.style.borderColor = "#cccbcb";
                      } else {
                        event.target.style.borderColor = "#9d1c21";
                      }
                      this.setState({
                        nombre: event.target.value,
                        nombreValido: valido,
                        error: null,
                      });
                    }}
                    onKeyUp={(event) => {
                      if (event.key === "Enter") {
                        document.getElementById("botonEnviar").click();
                      }
                    }}
                />
              </div>
              <div className="cv_campo_titulo">
                Fecha de nacimiento
                <input
                  type="date"
                  className="cv_campo"
                  style={styleCampo}
                  max={fecha}
                  onChange={(event) => {
                    let fn = new Date(event.target.value);
                    let valido = fn <= f;
                    if (valido) {
                      event.target.style.borderColor = "#cccbcb";
                    } else {
                      event.target.style.borderColor = "#9d1c21";
                    }
                    this.setState({
                      fecha_nacimiento: event.target.value,
                      fecha_nacimientoValido: valido,
                      error: null,
                    });
                  }}
                />
              </div>
              
            </div>
            <div style={{display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between", gap: 24}}>
            <div className="cv_campo_titulo">
                Teléfono
                <input
                  placeholder="Por favor ingrese su teléfono celular"
                    className="cv_campo"
                    type="tel"
                    style={styleCampo}
                    value={this.state.telefono}
                    onChange={(event) => {
                      event.target.value = event.target.value.replace(
                        /[^0-9\+\-\s]/g,
                        ""
                      );
                      let value = event.target.value;
                      let valido = 6 < value.length && value.length <= 30;
                      if (valido) {
                        event.target.style.borderColor = "#cccbcb";
                      } else {
                        event.target.style.borderColor = "#9d1c21";
                      }
                      this.setState({
                        telefono: value,
                        telefonoValido: valido,
                        error: null,
                      });
                    }}
                    onKeyUp={(event) => {
                      if (event.key === "Enter") {
                        document.getElementById("botonEnviar").click();
                      }
                    }}
                />
              </div>
              <div className="cv_campo_titulo">
                Email
                <input
                  placeholder="Por favor ingrese su correo electrónico"

                    className="cv_campo"
                    type="email"
                    style={styleCampo}
                    onChange={(event) => {
                      let valido = this.validarMail(event.target.value);
                      if (valido) {
                        event.target.style.borderColor = "#cccbcb";
                      } else {
                        event.target.style.borderColor = "#9d1c21";
                      }
                      this.setState({
                        email: event.target.value,
                        emailValido: valido,
                        error: null,
                      });
                    }}
                    onKeyUp={(event) => {
                      if (event.key === "Enter") {
                        document.getElementById("botonEnviar").click();
                      }
                    }}
                />
              </div>
            </div>
            
          </div>
        <div style={{width: "100%", height: 40}}/>
        <h2 className="cv_titulo">Adjuntá tu CV</h2>
        <div style={{width: "80%", marginLeft: "auto", marginRight: "auto"}}>
          <div>
                    <button
                      onClick={() =>
                        document.getElementById("archivoCV").click()
                      }
                      className="cv_archivo_boton"
                    >
                      {
                        this.state.archivoCV ? <IcoArchivo width={48} height={48} color={"#9d1c21"}/> : <IcoMas width={48} height={48}/>
                      }
                      {this.state.nombreCV}
                    </button>
                    <input
                      id="archivoCV"
                      type="file"
                      style={{
                        display: "block",
                        opacity: 0,
                        width: 0,
                        height: 1,
                      }}
                      onChange={(event) => {
                        let file = event.target.files[0];
                        if (file?.size <= 5242880) {
                          let tipos = [
                            "PDF",
                            "JPG",
                            "JPEG",
                            "DOC",
                            "DOCX",
                          ];
                          let ext = file.name
                            .split(".")
                            .slice(-1)[0]
                            .toUpperCase();
                          if (tipos.find((e) => e === ext)) {
                            this.setState({
                              archivoCV: file,
                              nombreCV: file.name,
                            });
                          } else {
                            alert(
                              "Los tipos soportados son doc, docx, pdf, y jpg."
                            );
                            event.target.files = [];
                            this.setState({
                              archivoCV: null,
                              nombreCV: "Elegir archivo",
                            });
                          }
                        } else {
                          alert("El archivo debe pesar menos de 5MB.");
                          event.target.files = [];
                          this.setState({
                            archivoCV: null,
                            nombreCV: "Elegir archivo",
                          });
                        }
                      }}
                    />
                  </div>
          <div className="cv_aclaracion">Solo se admiten extensiones .doc, .docx, .pdf y .jpg con un peso máximo de 5 MB.</div>
          <div style={{width: "100%", height: 120}}/>
          <div style={{width:"100%", display: "flex", justifyContent: "center"}}>
            <button className="cv_boton" onClick={() => {this.enviar()}} disabled={this.state.enviando || !this.state.archivoCV || !this.state.fecha_nacimientoValido || !this.state.nombreValido || !this.state.apellidoValido || !this.state.fecha_nacimientoValido || !this.state.emailValido}>Enviar CV</button>
          </div>
        </div>
        <div style={{width: "100%", height: "7vw"}}/>
    </React.Fragment>
    :
    <Navigate to="/recursos-humanos/formulario/exito" replace={true} />
    );
  }
}

export default CV;
