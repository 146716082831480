import React, { Component } from "react";
import { ReactComponent as IcoMenos } from "../../res/iconos/menos.svg";
import { ReactComponent as IcoMas } from "../../res/iconos/mas.svg";
import { CSSTransition } from "react-transition-group";

class Pregunta extends Component {
  async getURLImagen(imgPath) {
    const res = await fetch(imgPath);
    const blob = await res.blob();
    return URL.createObjectURL(blob);
  }

  async componentDidMount() {
    if (this.props.imagen) {
      console.log(this.props.imagen)
      const url = await this.getURLImagen(this.props.imagen);
      this.setState({url});
    }
  }

  state = { mostrarRespuesta: false };
  render() {
    const { pregunta, respuesta, imagen } = this.props;
    return (
      <div
        style={{
          maxWidth: "100%",
          margin: "auto",
          paddingTop: 6,
          paddingBottom: 6,
        }}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#F5F5F5",
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <button
            style={{
              display: "flex",
              width: "100%",
              background: "transparent",
              border: "none",
              paddingLeft: 25,
              paddingRight: 25,
            }}
            onClick={() => {
              this.setState({ mostrarRespuesta: !this.state.mostrarRespuesta });
            }}
          >
            <h1
              className="pregunta"
              style={{
                flex: 1,
                minWidth: "80%",
                textAlign: "left",
                margin: 0,
              }}
            >
              {pregunta}
            </h1>
            {this.state.mostrarRespuesta ? (
              <IcoMenos
                style={{
                  flex: 2,
                  alignSelf: "center",
                  paddingLeft: "15%",
                }}
                width={12}
                height={12}
              />
            ) : (
              <IcoMas
                style={{
                  flex: 2,
                  alignSelf: "center",
                  paddingLeft: "15%",
                }}
                width={12}
                height={12}
              />
            )}
          </button>
          <CSSTransition
            in={this.state.mostrarRespuesta}
            timeout={100}
            classNames="opacidad"
            unmountOnExit
          >
            <div
              className="respuesta"
              style={{
                height: "auto",
                paddingTop: 10,
                paddingLeft: 25,
                paddingRight: 25,
                textAlign: "justify",
                display: "flex",
                flexDirection: "column"
              }}
            >
              {respuesta}
              {this.state.url && 
              <img
                src={this.state.url}
              />
              }
            </div>
          </CSSTransition>
        </div>
      </div>
    );
  }
}

export default Pregunta;
