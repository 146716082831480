import React, { Component } from "react";
import { useParams, useNavigate } from "react-router-dom";
import calcularPx from "../general/calcularPx";

function withRouterAndNavigate(Component) {
  function ComponentWithRouterAndNavigate(props) {
    let params = useParams();
    let navigate = useNavigate();
    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouterAndNavigate;
}

class Verificar extends Component {
  state = { termino: false, error: null };

  async componentDidMount() {
    const res = await fetch("/api/cuentas/verificar", {
      method: "post",
      body: JSON.stringify({
        token: this.props.params.token,
      }),
      headers: { "Content-Type": "application/json" },
    });
    const data = await res.json();
    this.setState({ termino: true, error: data.error }, () => {
      setTimeout(() => {
        this.props.navigate("/");
      }, 4000);
    });
  }

  render() {
    const { termino, error } = this.state;
    return (
      <div
        style={{
          display: "flex",
          marginTop: calcularPx(45),
          paddingLeft: "10%",
          paddingRight: "10%",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1 className="tituloVerificar" style={{ textAlign: "center" }}>
          {!termino
            ? "Verificando..."
            : !error
            ? "¡Tu cuenta ya ha sido verificada!"
            : error === "expiroToken"
            ? "El link de verificación expiró. Enviamos uno nuevo a tu email."
            : "Hubo un error al verificar tu cuenta."}
        </h1>
        {termino && (
          <h1 className="mensajeVerificar" style={{ textAlign: "center" }}>
            Redirigiendo a la página principal...
          </h1>
        )}
      </div>
    );
  }
}

export default withRouterAndNavigate(Verificar);
