import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as IcoLogout } from "../../../res/iconos/logout.svg";
import { createRef } from "react";
import { CSSTransition } from "react-transition-group";
import { ReactComponent as IcoChevron } from "../../../res/iconos/chevron.svg";
import SubmenuAcceso from "./submenuAcceso";

class AccesoDesktop extends Component {
  state = { nivel: this.props.nivelLogin, mostrarSubmenu: false };

  //constructor, componentDidMount, componentWillUnmount y handleClickOutside son para ocultar el submenu tocando fuera de el
  constructor(props) {
    super(props);
    this.ref = createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  handleClickOutside(event) {
    if (this.ref.current && !this.ref.current.contains(event.target))
      this.setState({ mostrarSubmenu: false });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.nivelLogin != this.props.nivelLogin) {
      this.setState({ nivel: this.props.nivelLogin });
    }
  }

  style() {
    let style = {
      borderWidth: 1,
      borderColor: "#FFFFFF",
      borderRadius: 4,
      position: "relative",
      height: "100%",
      alignContent: "center",
      alignSelf: "center",
      textAlign: "center",
    };
    return style;
  }

  render() {
    let retorno;
    switch (this.state.nivel) {
      case 0:
        retorno = (
          <div 
            style={{
              position: "relative",
              width: 130,
              height: "100%",
              alignContent: "center",
              alignSelf: "center",
            }} 
            ref={this.ref}
            onClick={() => {
              this.props.ocultar();
            }}
            onMouseEnter={
             () => {
                    this.setState({ mostrarSubmenu: true });
                  }
            }
            onMouseLeave={
              () => {
                    this.setState({ mostrarSubmenu: false });
                  }
            }
          >
            <div className={"itemNavBar"}
              style={{
                display: "flex",
                gap: 13,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "#FFFFFF",
                borderRadius: 4,
                width: "100%",
                height: 48,
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
                textAlign: "center",
              }}>
              Ingresar
              <div
                style={{
                  position: "static",
                  left: "90%",
                  width: 0.75, 
                  height: 28, 
                  backgroundColor: "#FFFFFF"}}
              />
              <CSSTransition
                in={this.state.mostrarSubmenu}
                timeout={250}
                classNames="girar"
              >
                <IcoChevron
                  width={15}
                  height={15}
                  style={{
                    position: "static",
                    left: "90%",
                  }}
                  stroke="#FFFFFF"
                />
              </CSSTransition>
            </div>
              
            <CSSTransition
              in={this.state.mostrarSubmenu}
              timeout={250}
              classNames="submenu"
              unmountOnExit
            >
              {<SubmenuAcceso ocultarMenu={this.props.ocultar} />}
            </CSSTransition>
          </div>
        );
        break;

      default:
        retorno = (
          <>
            <Link
              to={this.state.nivel == 3 ? "/admin" : "/comerciantes"}
              className="itemNavBar"
              onClick={() => {
                this.props.ocultar();
              }}
              style={this.style()}
            >
              {this.state.nivel == 3 ? "Administración" : "Comerciantes"}
            </Link>
            <button
              className="itemNavBar"
              style={{
                border: "none",
                background: "transparent",
                marginLeft: 20,
              }}
              onClick={() => {
                this.props.ocultar();
                this.props.botones.cerrarSesion();
              }}
            >
              Salir
              <IcoLogout
                style={{ marginLeft: 5 }}
                fill="#FFFFFF"
                width={30}
                height={30}
              />
            </button>
          </>
        );
        break;
    }
    return retorno;
  }
}

export default AccesoDesktop;
