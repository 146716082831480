import React, { Component } from "react";
import calcularPx from "../calcularPx";
import { ReactComponent as IcoMarker } from "../../../res/mapa/marker-vacio.svg";
import { ReactComponent as IcoTelefono } from "../../../res/mapa/telefono.svg";

class Card extends Component {
  state = { isSeleccionado: false };

  componentDidUpdate(prevProps) {
    if (prevProps.idSeleccionado !== this.props.idSeleccionado)
      this.setState({
        isSeleccionado: this.props.idSeleccionado === this.props.punto.id,
      });
  }

  render() {
    const { id, lat, lng, nombre, domicilio, telefono } = this.props.punto;
    const verMapa = this.props.verMapa;
    return (
      <div id={"card" + id}>
        <div
          onClick={
            verMapa
              ? () =>
                  this.props.seleccionar({
                    id: id,
                    lat: Number(lat),
                    lng: Number(lng),
                  })
              : () => {}
          }
          style={{
            display: "block",
            width: "100%",
            height: "min-content",
            border: !this.state.isSeleccionado
              ? "0px solid #f8f8f8"
              : "1px solid #9d1c21",
            backgroundColor: "#f8f8f8",
            borderRadius: 3,
            padding: 15,
          }}
        >
          {nombre && nombre.trim().length > 0 && (
            <div className="tituloPV" style={{ paddingBottom: 10 }}>
              {nombre}
            </div>
          )}
          <div
            style={{
              display: verMapa ? "" : "flex",
              gap: verMapa ? "0" : 32,
            }}
          >
            {domicilio && domicilio.trim().length > 0 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: verMapa ? "100%" : "50%",
                  paddingBottom: 10,
                }}
              >
                <IcoMarker
                  style={{ flexShrink: 0 }}
                  width={25}
                  height={25}
                />
                <div
                  className="dropPV"
                  style={{
                    paddingLeft: 5,
                  }}
                >
                  {domicilio}
                </div>
              </div>
            )}
            {telefono && telefono.trim().length > 0 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: verMapa ? "100%" : "50%",
                  paddingBottom: 10,
                }}
              >
                <IcoTelefono
                  style={{ flexShrink: 0 }}
                  width={25}
                  height={25}
                />
                <div
                  className="dropPV"
                  style={{
                    paddingLeft: calcularPx(5),
                  }}
                >
                  {telefono.toUpperCase()}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Card;
