import React, { Component } from "react";
import { ReactComponent as IcoLogo } from "../../res/banners/logo.svg";
import { Link } from "react-router-dom";

class EnConstruccion extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div style={{width: "80%", marginLeft: "auto", marginRight: "auto"}}>
        <div style={{ width: "100%", height: "11vw" }} />
        <div style={{ display: "flex", flexDirection: "column", width: "70%", marginLeft: "auto", marginRight: "auto", alignContent: "center", alignItems: "center"}}>
          <IcoLogo width={584} height={93}/>
        <div style={{width: "100%", height: 20}}/>
          <div className="construccion_titulo">PÁGINA EN CONSTRUCCIÓN</div>
        <div style={{width: "100%", height: 50}}/>
          <Link to="/">
          <div className="construccion_boton">
          Volver a la home
          </div>
          </Link>
        </div>
        <div style={{width: "100%", height: "7vw"}}/>
      </div>
    );
  }
}

export default EnConstruccion;
