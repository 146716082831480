import React, { Component } from "react";
import BannersDesktop from "./bannersDesktop.jsx";
import BannersMobile from "./bannersMobile.jsx";
import Carousel from "react-multi-carousel";
import { Link } from "react-router-dom";

class Home extends Component {
  state = { primero: [], segundo: [], tercero: [], cuarto: [] };

  async getURLImagen(imgPath) {
    const res = await fetch(imgPath);
    const blob = await res.blob();
    return URL.createObjectURL(blob);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    fetch("/api/home/banners")
      .then((res) => {
        return res.json();
      })
      .then(async (data) => {
        let i = 0;
        let primero = [];
        let segundo = [];
        let tercero = [];
        let cuarto = [];
        while (i < data.length) {
          let e = data[i];
          let banner = {
            titulo: e.titulo,
            subtitulo1: e.subtitulo1,
            subtitulo2: e.subtitulo2,
            link: e.link,
            plataforma: e.plataforma,
          };
          banner.url = await this.getURLImagen(e.imgPath);
          switch (e.carousel) {
            case 1:
              primero.push(banner);
              break;
            case 2:
              segundo.push(banner);
              this.setState({ primero });
              break;
            case 3:
              tercero.push(banner);
              this.setState({ primero, segundo });
              break;
            case 4:
              cuarto.push(banner);
              this.setState({ primero, segundo, tercero });
              break;
          }
          i++;
        }
        this.setState({ primero, segundo, tercero, cuarto });
      });
  }

  componentWillUnmount() {
    this.state.primero.forEach((e) => {
      URL.revokeObjectURL(e.url);
    });
    this.state.segundo.forEach((e) => {
      URL.revokeObjectURL(e.url);
    });
    this.state.tercero.forEach((e) => {
      URL.revokeObjectURL(e.url);
    });
    this.state.cuarto.forEach((e) => {
      URL.revokeObjectURL(e.url);
    });
  }

  render() {
    const mobile = window.innerWidth < 950;
    const maxCalefactores = Math.floor((window.innerWidth*0.9) / (321+20));
    const cantCalefactores = Math.min(3, maxCalefactores);
    const responsiveCalefactores = {
      todo: { breakpoint: { max: 10000, min: 0 }, items: cantCalefactores, slidesToSlide: cantCalefactores },
    };
    const maxTermotanques = Math.floor((window.innerWidth*0.9) / (240+70));
    const cantTermotanques = Math.min(3, maxTermotanques);
    const responsiveTermotanques = {
      todo: { breakpoint: { max: 10000, min: 0 }, items: cantTermotanques, slidesToSlide: cantTermotanques },
    };
    return (
      <React.Fragment>
        {mobile ? (
          <BannersMobile banners={this.state} />
        ) : (
          <BannersDesktop banners={this.state} />
        )}
      <div style={{
        display: "flex",
        gap: 100,
        paddingTop: "7vw",
        paddingBottom: "7vw",
        paddingLeft: "10%",
        paddingRight: "10%",
        flexWrap: "wrap",
        backgroundColor: "#f4f4f2",
        justifyContent: "space-between",
        alignContent: "stretch",
        alignItems: "center",
      }}>
        <div style={{flex: 42, alignSelf: "stretch", display: "flex", minWidth: "min(80vw,30%)", width: "80%", flexWrap: "wrap", gap: 10, alignItems: "space-between"}}>
          <h1 className="home_nosotros_titulo">Sobre Nosotros</h1>
          <p className="home_nosotros_subtitulo">Desde 1967 brindando soluciones para el hogar.</p>
          <p className="home_nosotros_texto">Con una planta de 40.000 m², impulsamos la creación de empleo genuino. Confiamos en el potencial de la industria argentina, logrando que nuestros productos sean competitivos y de alta calidad.</p>
          <Link className="home_nosotros_link" to="/sobre-nosotros">
            <button className="home_nosotros_boton">Ver más</button>
          </Link>
        </div>
        <div style={{flex: 48, minWidth: 'min(80vw,730px)', height: "100%", aspectRatio: 16/9}}>
          <div style={{position: "relative", width: "100%", height: "100%"}}>
            <iframe style={{position: "absolute", width: "100%", height: "100%", left: 0, top: 0}} width="560" height="315" frameborder="0" src="https://www.youtube.com/embed/KmpBFzl9PoI?si=U2aD35lOcc-nrIvB" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
        </div>
      </div>

      <div style={{height: "4vw"}}/>
      <div style={{width: "80%", marginRight: "auto",marginLeft: "auto"}}>
        <h1 className="home_titulo">Calefacción</h1>
        <div style={{height: "2vw"}}/>
        <p className="home_texto">Convectores a gas de amplia distribución de calor frontal, con múltiples opciones de tirajes y salidas para cada tipo de espacio.</p>
        <div style={{height: "2vw"}}/>
        <div style={{width: cantCalefactores*(321+20), marginLeft: "auto", marginRight: "auto"}}>
        <Carousel
          responsive={responsiveCalefactores}
          showDots={true}
          draggable={false}
          infinite={true}
          autoPlay={true}
          partialVisible={false}   
          customLeftArrow={<></>}
          customRightArrow={<></>}
        >
          {this.state.segundo
            .map((e, i) => {
              return (
                <Link 
                  to={e.link} 
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    height: 432,
                    width: 321,
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                  className="home_producto"
                > 
                    <div
                      style={{
                        width: "100%",
                        height: "auto",
                      }}
                    >
                      <img
                        alt=""
                        src={e.url}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        height: 116,
                        background: "transparent",
                      }}
                    >
                      <h1
                        className="home_producto_texto1"
                      >
                        {e.titulo}
                      </h1>
                      <h1
                        className="home_producto_texto2"
                      >
                        {e.subtitulo1}
                      </h1>
                      <h1
                        className="home_producto_texto2"
                      >
                        {e.subtitulo2}
                      </h1>
                    </div>
                </Link>
              );
            })}
        </Carousel>
        </div>
      </div>
      <div style={{height: "4vw"}}/>
      <div className="home_calculo">
        <h2 className="home_calculo_texto">¡Descubrí tu convector ideal!</h2>
          <Link to={"/calculo-termico"}>
            <h3 className="home_calculo_boton">Ir al cálculo</h3>
          </Link>
      </div>

      <div style={{height: "5vw"}}/>
      <div style={{width: "80%", marginRight: "auto",marginLeft: "auto"}}>
        <h1 className="home_titulo">Agua caliente</h1>
        <div style={{height: "2vw"}}/>
        <p className="home_texto">¡Agua caliente siempre! Descubrí nuestra amplia gama
        de termotanques a gas y eléctricos.</p>
        <div style={{height: "2vw"}}/>
        <div style={{width: cantTermotanques*(240+70), marginLeft: "auto", marginRight: "auto"}}>
        <Carousel
          responsive={responsiveTermotanques}
          showDots={true}
          draggable={false}
          infinite={true}
          autoPlay={true}
          partialVisible={false}   
          customLeftArrow={<></>}
          customRightArrow={<></>}
        >
          {this.state.tercero
            .map((e, i) => {
              return (
                <Link 
                  to={e.link} 
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    height: 826,
                    width: 240,
                    marginLeft: 35,
                    marginRight: 35,
                  }}
                  className="home_producto"
                > 
                    <div
                      style={{
                        width: "100%",
                        height: "auto",
                      }}
                    >
                      <img
                        alt=""
                        src={e.url}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        height: 116,
                        background: "transparent",
                      }}
                    >
                      <h1
                        className="home_producto_texto1"
                      >
                        {e.titulo}
                      </h1>
                      <h1
                        className="home_producto_texto2"
                      >
                        {e.subtitulo1}
                      </h1>
                      <h1
                        className="home_producto_texto2"
                      >
                        {e.subtitulo2}
                      </h1>
                    </div>
                </Link>
              );
            })}
        </Carousel>
        </div>
        
      </div>
      <div style={{height: "4vw"}}/>
      <div className="home_calculo">
        <h2 className="home_calculo_texto">¡Encontrá tu termotanque ideal!</h2>
          <Link to={"/calculo-agua"}>
            <h3 className="home_calculo_boton">Ir al cálculo</h3>
          </Link>
      </div>

      <div style={{height: "7vw"}}/>

      <div style={{width: "80%", marginRight: "auto",marginLeft: "auto", display: "flex", flexWrap: "wrap", gap: 25, justifyContent: "space-between", alignContent: "center", justifyItems: "center"}}>
        <Link className="home_imagen" to="/profesionales/registro">
          <img
            src={require("../../res/banners/home_profesionales.jpg")}
            className="home_imagen"
            style={{
              position: "relative",
              flexGrow: 0, 
              flex: 1,
              objectFit: "fit",
              objectPosition: "center",
              minWidth: 300,
              maxWidth: "100%",
              height: "auto",
              margin: "auto",
              zIndex: 0,
            }}
          />
        </Link>
        
        <Link className="home_imagen" to="/comerciantes/registro">
          <img
            src={require("../../res/banners/home_comerciantes.jpg")}
            className="home_imagen"
            style={{
              position: "relative",
              flexGrow: 0, 
              flex: 1,
              objectFit: "fit",
              objectPosition: "center",
              minWidth: 300,
              maxWidth: "100%",
              height: "auto",
              margin: "auto",
              zIndex: 0,
            }}
          />
        </Link>
      </div>

      <div style={{height: "9vw"}}/>
      </React.Fragment>
    );
  }
}

export default Home;
