import React, { Component } from "react";
import { ReactComponent as Logo } from "../../../res/iconos/logoFooter.svg";
import { ReactComponent as IcoInstagram } from "../../../res/iconos/instagram.svg";
import { ReactComponent as IcoFacebook } from "../../../res/iconos/facebook.svg";
import { ReactComponent as IcoYoutube } from "../../../res/iconos/youtube.svg";
import { ReactComponent as IcoLinkedIn } from "../../../res/iconos/linkedin.svg";

import { Link } from "react-router-dom";

const FooterDesktop = () => {
  return <React.Fragment>
  <div style={{
    width: "100%",
    height: "auto",
    backgroundColor: "#1d1f23",
    paddingLeft: "10%", 
    paddingRight: "10%"
  }}>
    <div style={{width: "100%", height: 40}}/>

    <div style={{
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    }}>
      <div>
        <h4 className="footer_categoria">
          PRODUCTOS
        </h4>
        <Link
          to="/calefactores"
        >
          <div className="footer_subcategoria">
            Calefacción
          </div>
        </Link>
        <Link
          to="/termotanques"
        >
          <div className="footer_subcategoria">
            Agua caliente
          </div>
        </Link>
        <Link
          to="/hornos"
        >
          <div className="footer_subcategoria">
            Cocina
          </div>
        </Link>
      </div>
      <div>
        <h4 className="footer_categoria">
          INFORMACIÓN
        </h4>
        <Link
          to="/sobre-nosotros"
        >
          <div className="footer_subcategoria">
            Sobre nosotros
          </div>
        </Link>
        <Link
          to="/recursos-humanos"
        >
          <div className="footer_subcategoria">
            Recursos humanos
          </div>
        </Link>
        <Link
          to="/registrar-producto"
        >
          <div className="footer_subcategoria">
            Registrar garantía
          </div>
        </Link>
      </div>
      <div>
        <h4 className="footer_categoria">
          SOPORTE
        </h4>
        <Link
          to="/puntos-de-venta.php"
        >
          <div className="footer_subcategoria">
            Dónde comprar
          </div>
        </Link>
        <Link
          to="/preguntas-frecuentes"            >
          <div className="footer_subcategoria">
            Preguntas frecuentes
          </div>
        </Link>
        <Link
          to="/posventa"
        >
          <div className="footer_subcategoria">
            Solicitar Atención posventa
          </div>
        </Link>
        <Link
          to="/contacto"
        >
          <div className="footer_subcategoria">
            Contacto
          </div>
        </Link>
      </div>
      <div>
        <h4 className="footer_categoria">
          REDES SOCIALES
        </h4>
        <div style={{display: "flex", gap: 50, marginTop: 20}}>
        <a className="footer_red" href="https://www.facebook.com/coppens.ar">
          <IcoFacebook width={28}/>
        </a>
        <a className="footer_red" href="https://www.instagram.com/coppens.ar/">
          <IcoInstagram width={28}/>
        </a>
        <a className="footer_red" href="https://www.youtube.com/@coppens.soluciones">
          <IcoYoutube width={28}/>
        </a>
        <a className="footer_red" href="https://www.linkedin.com/company/coppens-s-a">
          <IcoLinkedIn width={28}/>
        </a>
        </div>
      </div>
    </div>

    <div style={{width: "100%", height: 40}}/>
    <div style={{width: "100%", height: 1, backgroundColor: "#cccbcb"}}/>
    <div style={{width: "100%", height: 50}}/>

    <Logo style={{margin: "auto", width: "100%"}} width={270} height={45} />
    <div style={{width: "100%", height: 15}}/>
    <p className="footer_copy">Copyright ©  2024 Coppens S.A. Todos los derechos reservados.</p>
    <div style={{width: "100%", height: 15}}/>
    <div style={{width: "100%", height: 28, display: 'flex', alignContent: "center", alignItems: "center", justifyContent: "center", justifyItems: "center", gap: 50}}>
      <Link
        to="/informacion-legal"
      >
        <div className="footer_legal">
          Información legal
        </div>
      </Link>
      <div style={{backgroundColor: "#cccbcb", width: 1, height: 12}}/>
      <Link
        to="/politica-de-privacidad"
      >
        <div className="footer_legal">
          Política de privacidad
        </div>
      </Link>
    </div>

    <div style={{width: "100%", height: 40}}/>
  </div>
</React.Fragment>;
};

export default FooterDesktop;
