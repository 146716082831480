import React, { Component } from "react";
import Gracias from "../general/gracias";

class RegistroComercianteExito extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
      }
    render() { 
        return (<Gracias mensaje1="Su registro fue enviado" mensaje2="Nuestro equipo del área comercial validará los datos para dar de alta su usuario en la web." aclaracion="Recuerde revisar la casilla de su correo electrónico."/>
        );
    }
}
 
export default RegistroComercianteExito;
