import React, { Component } from "react";
import Gracias from "../general/gracias";

class CVExito extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
      }
    render() { 
        return (<Gracias mensaje1="El formulario fue enviado" mensaje2="Nuestro equipo de Recursos Humanos procederá a evaluar la información proporcionada en el caso de que se considere una posible oportunidad laboral."/>);
    }
}
 
export default CVExito;
