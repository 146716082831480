import React, { Component } from "react";
import Gracias from "../general/gracias";

class RegistrarProductoExito extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
      }
    render() { 
        return (<Gracias mensaje1="Su garantía fue registrada" mensaje2="Nuestro equipo validará los datos para dar extender los
            beneficios de su equipo por más tiempo." aclaracion="Recuerde revisar la casilla de su correo electrónico."/>);
    }
}
 
export default RegistrarProductoExito;
