import React, { Component, useState } from "react";
import { ReactComponent as Logo } from "../../../res/iconos/logoFooter.svg";
import { ReactComponent as IcoInstagram } from "../../../res/iconos/instagram.svg";
import { ReactComponent as IcoFacebook } from "../../../res/iconos/facebook.svg";
import { ReactComponent as IcoYoutube } from "../../../res/iconos/youtube.svg";
import { ReactComponent as IcoLinkedIn } from "../../../res/iconos/linkedin.svg";
import { ReactComponent as IcoChevron } from "../../../res/iconos/chevron.svg";
import { CSSTransition } from "react-transition-group";

import { Link } from "react-router-dom";

const FooterMobile = () => {
  let [mostrarProductos, setMostrarProductos] = useState(false);
  let [mostrarInformacion, setMostrarInformacion] = useState(false);
  let [mostrarSoporte, setMostrarSoporte] = useState(false);

  const productos = 
  <React.Fragment>
    <Link
          to="/calefactores"
        >
          <div className="footer_subcategoria">
            Calefacción
          </div>
        </Link>
        <Link
          to="/termotanques"
        >
          <div className="footer_subcategoria">
            Agua caliente
          </div>
        </Link>
        <Link
          to="/hornos"
        >
          <div className="footer_subcategoria">
            Cocina
          </div>
        </Link>
  </React.Fragment>;

  const informacion = <React.Fragment>
    <Link
      to="/sobre-nosotros"
    >
      <div className="footer_subcategoria">
        Sobre nosotros
      </div>
    </Link>
    <Link
      to="/recursos-humanos"
    >
      <div className="footer_subcategoria">
        Recursos humanos
      </div>
    </Link>
    <Link
      to="/registrar-producto"
    >
      <div className="footer_subcategoria">
        Registrar garantía
      </div>
    </Link>
  </React.Fragment>

  const soporte = <React.Fragment>
    <Link
      to="/puntos-de-venta.php"
    >
      <div className="footer_subcategoria">
        Dónde comprar
      </div>
    </Link>
    <Link
      to="/preguntas-frecuentes"            >
      <div className="footer_subcategoria">
        Preguntas frecuentes
      </div>
    </Link>
    <Link
      to="/posventa"
    >
      <div className="footer_subcategoria">
        Solicitar Atención posventa
      </div>
    </Link>
    <Link
      to="/contacto"
    >
      <div className="footer_subcategoria">
        Contacto
      </div>
    </Link>
  </React.Fragment>


  const styleCategoria = {
    background: "transparent",
    border: "none",
    position: "relative",
    padding: "16px 10%",
    textAlign: "left",
    display: "block",
    width: "100%"
  }

  return <React.Fragment>
    <div style={{backgroundColor: "#1d1f23"}}>
      <button
        className="footer_categoria"
        style={styleCategoria}
        onClick={() => {
          setMostrarProductos(!mostrarProductos);
        }}
      >
        PRODUCTOS
        <CSSTransition
          in={mostrarProductos}
          timeout={250}
          classNames="girar"
        >
          <IcoChevron
            width={15}
            height={15}
            style={{
              position: "absolute",
              right: "10%",
              top: 23.5,
            }}
            stroke={"#ffffff"}
          />
        </CSSTransition>
        <CSSTransition
          in={mostrarProductos}
          timeout={250}
          classNames="submenu"
          unmountOnExit
        >
          <div 
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              gap: 32,
              paddingTop: 32,
              paddingBottom: 32,
              width: "auto"
            }}
          >
            {productos}
          </div>
        </CSSTransition>
      </button>
      <button
        className="footer_categoria"
        style={styleCategoria}
        onClick={() => {
          setMostrarInformacion(!mostrarInformacion);
        }}
      >
        INFORMACIÓN
        <CSSTransition
          in={mostrarInformacion}
          timeout={250}
          classNames="girar"
        >
          <IcoChevron
            width={15}
            height={15}
            style={{
              position: "absolute",
              right: "10%",
              top: 23.5,
            }}
            stroke={"#ffffff"}
          />
        </CSSTransition>
        <CSSTransition
          in={mostrarInformacion}
          timeout={250}
          classNames="submenu"
          unmountOnExit
        >
          <div 
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              gap: 32,
              paddingTop: 32,
              paddingBottom: 32,
              width: "auto"
            }}
          >
            {informacion}
          </div>
        </CSSTransition>
      </button>
      <button
        className="footer_categoria"
        style={styleCategoria}
        onClick={() => {
          setMostrarSoporte(!mostrarSoporte);
        }}
      >
        SOPORTE
        <CSSTransition
          in={mostrarSoporte}
          timeout={250}
          classNames="girar"
        >
          <IcoChevron
            width={15}
            height={15}
            style={{
              position: "absolute",
              right: "10%",
              top: 23.5,
            }}
            stroke={"#ffffff"}
          />
        </CSSTransition>
        <CSSTransition
          in={mostrarSoporte}
          timeout={250}
          classNames="submenu"
          unmountOnExit
        >
          <div 
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              gap: 32,
              paddingTop: 32,
              paddingBottom: 32,
              width: "auto"
            }}
          >
            {soporte}
          </div>
        </CSSTransition>
      </button>
      <div style={{width: "80%", marginLeft: "auto", marginRight: "auto", paddingTop: 16}}>
        <h4 className="footer_categoria">
          REDES SOCIALES
        </h4>
        <div style={{display: "flex", width: "100%", justifyContent: "space-between", gap: 20, marginTop: 24}}>
          <a className="footer_red" href="https://www.facebook.com/coppens.ar">
            <IcoFacebook width={28}/>
          </a>
          <a className="footer_red" href="https://www.instagram.com/coppens.ar/">
            <IcoInstagram width={28}/>
          </a>
          <a className="footer_red" href="https://www.youtube.com/@coppens.soluciones">
            <IcoYoutube width={28}/>
          </a>
          <a className="footer_red" href="https://www.linkedin.com/company/coppens-s-a">
            <IcoLinkedIn width={28}/>
          </a>
        </div>
        <div style={{width: "100%", height: 48}}/>
        <div style={{width: "100%", height: 1, backgroundColor: "#cccbcb"}}/>
        <div style={{width: "100%", height: 48}}/>
        <Logo style={{margin: "auto", width: "100%"}} width={270} height={45} />
        <div style={{width: "100%", height: 29}}/>
        <p className="footer_copy">Copyright ©  2024 Coppens S.A. Todos los derechos reservados.</p>
        <div style={{width: "100%", height: 29}}/>
        <div style={{width: "100%", height: 28, display: 'flex', alignContent: "center", alignItems: "center", justifyContent: "center", justifyItems: "center", gap: 50}}>
          <Link
            to="/informacion-legal"
          >
            <div className="footer_legal" style={{fontSize: 12}}>
              Información legal
            </div>
          </Link>
          <div style={{backgroundColor: "#cccbcb", width: 1, height: 12}}/>
          <Link
            to="/politica-de-privacidad"
          >
            <div className="footer_legal" style={{fontSize: 12}}>
              Política de privacidad
            </div>
          </Link>
        </div>
        <div style={{width: "100%", height: 20}}/>
      </div>
    </div>
</React.Fragment>;
};

export default FooterMobile;
