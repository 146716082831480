import React, { Component } from "react";

import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import calcularPx from "./calcularPx.js";
import { CSSTransition } from "react-transition-group";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";

import NavBar from "./navBar/navBar.jsx";
import Home from "../home/paginaHome.jsx";
import Productos from "../productos/paginaProductos.jsx";
import Detalle from "../productos/paginaDetalle.jsx";
import Comprar from "../productos/paginaComprar.jsx";
import PuntosDeVenta from "../puntosDeVenta/paginaPuntosDeVenta.jsx";
import PosventaExito from "../posventa/paginaPosventaExito.jsx";
import Posventa from "../posventa/paginaPosventa.jsx";
import Contacto from "../contacto/paginaContacto.jsx";
import PreguntasFrecuentes from "../preguntasFrecuentes/paginaPreguntasFrecuentes.jsx";
import CalculoTermico from "../calculo/paginaCalculoTermico.jsx";
import CalculoAgua from "../calculo/paginaCalculoAgua.jsx";
import Comerciantes from "../comerciantes/paginaComerciantes.jsx";
import Verificar from "../acceso/paginaVerificar.jsx";
import Cambiar from "../acceso/paginaCambiar.jsx";
import NotFound from "./paginaNotFound.jsx";
import FooterDesktop from "./footer/footerDesktop.jsx";
import RegistrarProducto from "../registrarProducto/paginaRegistrarProducto.jsx";
import Legal from "../legal/paginaLegal.jsx";
import Politica from "../politica/paginaPolitica.jsx";
import RRHH from "../rrhh/paginaRRHH.jsx";
import CVExito from "../rrhh/paginaCVExito.jsx";
import CV from "../rrhh/paginaCV.jsx";
import IngresoComerciante from "../acceso/paginaIngresoComerciante.jsx";
import IngresoProfesional from "../acceso/paginaIngresoProfesional.jsx";
import RegistroComerciante from "../acceso/paginaRegistroComerciante.jsx";
import RegistroProfesional from "../acceso/paginaRegistroProfesional.jsx";
import RegistroComercianteExito from "../acceso/paginaRegistroComercianteExito.jsx";
import RegistroProfesionalExito from "../acceso/paginaRegistroProfesionalExito.jsx";
import EnConstruccion from "./paginaEnConstruccion.jsx";
//import CentroDeTuHogarExito from "../concursos/paginaCentroDeTuHogarExito.jsx";
//import CentroDeTuHogar from "../concursos/paginaCentroDeTuHogar.jsx";
//import CentroDeTuHogarTYC from "../concursos/tycCentroDeTuHogar.jsx";
import Atencion from "../atencion/paginaAtencion.jsx";
import SobreNosotros from "../sobre_nosotros/paginaSobreNosotros.jsx";
import FooterMobile from "./footer/footerMobile.jsx";
import RegistrarProductoExito from "../registrarProducto/paginaRegistrarProductoExito.jsx";

class Sitio extends Component {
  state = {
    width: 0,
    nivelLogin: 0, //0: no logueado, 1: cliente, 3:admin
    cargo: false,
  };

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);
    this.actualizarAncho = this.actualizarAncho.bind(this);
  }

  componentDidMount() {
    fetch("/api/refrescarToken")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        let token = this.props.cookies.get("token");
        if (token && token !== undefined && token !== "null") {
          this.setState({ nivelLogin: data.nivelLogin, cargo: true });
        } else {
          this.props.cookies.remove("token");
          this.setState({ nivelLogin: 0, cargo: true });
        }
        this.actualizarAncho();
        window.addEventListener("resize", this.actualizarAncho);
      });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.actualizarAncho);
  }

  actualizarAncho() {
    this.setState({
      width: typeof window !== "undefined" ? window.innerWidth : 0,
    });
  }

  ocultarAcceso() {
    this.setState({ mostrarIngresar: false, mostrarRegistrar: false });
  }

  mostrarIngresar() {
    this.setState({ mostrarRegistrar: false }, () => {
      this.setState({ mostrarIngresar: true });
    });
  }

  mostrarRegistrar() {
    this.setState({ mostrarIngresar: false }, () => {
      this.setState({ mostrarRegistrar: true });
    });
  }

  cerrarSesion() {
    this.props.cookies.remove("token");
    this.setState({ nivelLogin: 0 });
  }

  render() {
    const mobileFooter = window.innerWidth < 950;

    return (
      <div>
        <div
          style={{
            position: "relative",
            minHeight: "calc(100vh - " + calcularPx(303.49) + ")",
          }}
        >
          <NavBar
            nivelLogin={this.state.nivelLogin}
            botones={{
              ingresar: this.mostrarIngresar.bind(this),
              registrar: this.mostrarRegistrar.bind(this),
              cerrarSesion: this.cerrarSesion.bind(this),
            }}
          />
          <Routes>
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="/home" element={<Home />} />            
            <Route path="/puntos-de-venta.php" element={<PuntosDeVenta />} />
            <Route path="/contacto" element={<Contacto />} />
            <Route path="/informacion-legal" element={<Legal />} />
            <Route path="/politica-de-privacidad" element={<Politica />} />
            <Route path="/comerciantes/login" element={<IngresoComerciante setLogin={(nivel) => {
                this.setState({ nivelLogin: nivel });
              }} />} />
            <Route path="/comerciantes/registro/exito" element={<RegistroComercianteExito />} />
            <Route path="/comerciantes/registro" element={<RegistroComerciante />} />
            <Route path="/profesionales/login" element={<IngresoProfesional setLogin={(nivel) => {
                this.setState({ nivelLogin: nivel });
              }}/>} />
            <Route path="/profesionales/registro/exito" element={<RegistroProfesionalExito />} />
            <Route path="/profesionales/registro" element={<RegistroProfesional />} />
            <Route path="/recursos-humanos/formulario/exito" element={<CVExito />} />
            <Route path="/recursos-humanos/formulario" element={<CV />} />
            <Route path="/recursos-humanos" element={<RRHH />} />
            <Route path="/posventa/exito" element={<PosventaExito />} />
            <Route path="/posventa" element={<Posventa />} />
            <Route path="/sobre-nosotros" element={<SobreNosotros />} />
            <Route path="/atencion-al-cliente" element={<Atencion />} />
            <Route path="/atencion-al-cliente/videos" element={<EnConstruccion />} />
            {/*
            <Route path="/concurso/exito" element={<CentroDeTuHogarExito />} />
            <Route path="/concurso" element={<CentroDeTuHogar />} />
            <Route path="/concurso/bases" element={<CentroDeTuHogarTYC />} />
            <Route path="/servicioTecnico" element={<ServicioTecnico />} />
            */}

            <Route
              path="/preguntas-frecuentes"
              element={<PreguntasFrecuentes />}
            />
            <Route
              path="/registrar-producto/exito"
              element={
                <RegistrarProductoExito />
              }
            />
            <Route
              path="/registrar-producto"
              element={
                <RegistrarProducto />
              }
            />
            <Route path="/calculo-termico" element={<CalculoTermico/>} />
            <Route path="/calculo-agua" element={<CalculoAgua />} />
            <Route
              path="/comerciantes"
              element={
                <Comerciantes
                  seccion={false}
                  nivelLogin={this.state.nivelLogin}
                  cargo={this.state.cargo}
                  cerrarSesion={() => this.cerrarSesion()}
                />
              }
            />
            <Route
              path="/comerciantes/:seccion"
              element={
                <Comerciantes
                  seccion={true}
                  nivelLogin={this.state.nivelLogin}
                  cargo={this.state.cargo}
                  cerrarSesion={() => this.cerrarSesion()}
                />
              }
            />
            <Route path="/verificar/:token" element={<Verificar />} />
            <Route path="/cambiar/:token" element={<Cambiar />} />
            <Route path="/404" element={<NotFound />} />
            <Route
              path="/:categoria/:url/comprar"
              element={<Comprar />}
            />
            <Route
              path="/:categoria/:url"
              element={<Detalle />}
            />
            <Route path="/:categoria" element={<Productos />} />
            <Route path="*" element={<Navigate to="/404" />} />
          </Routes>
        </div>
        {mobileFooter?<FooterMobile />:<FooterDesktop />}
      </div>
    );
  }
}

export default withCookies(Sitio);
