import React, { Component } from "react";
import { Link } from "react-router-dom";
import calcularPx from "../calcularPx.js";
import { ReactComponent as IcoChevron } from "../../../res/iconos/chevron.svg";
import SubmenuProductos from "./submenuProductos.jsx";
import SubmenuContacto from "./submenuContacto.jsx";
import { createRef } from "react";
import { CSSTransition } from "react-transition-group";
import AccesoDesktop from "../acceso/menuAccesoDesktop.jsx";
import AccesoMobile from "../acceso/menuAccesoMobile.jsx";

class Menu extends Component {
  state = { mostrarSubmenuProductos: false, mostrarSubmenuContacto: false, nivelLogin: this.props.nivelLogin };

  //constructor, componentDidMount, componentWillUnmount y handleClickOutside son para ocultar el submenu de productos tocando fuera de el
  constructor(props) {
    super(props);
    this.ref1 = createRef();
    this.ref2 = createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.nivelLogin != this.props.nivelLogin) {
      this.setState({ nivelLogin: this.props.nivelLogin });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  handleClickOutside(event) {
    if (this.ref1.current && !this.ref1.current.contains(event.target))
      this.setState({ mostrarSubmenuProductos: false });
    if (this.ref2.current && !this.ref2.current.contains(event.target))
      this.setState({ mostrarSubmenuContacto: false });
  }

  style() {
    let style = {
      background: "transparent",
      border: "none",
    };
    if (this.props.mobile) {
      style.position = "relative";
      style.display = "block";
      style.width = "100%";
      style.padding = "30px 10%";
      style.textAlign = "left";
      style.color = "#41454c";
      style.fontWeight = 700;
    } else {
      style.position = "relative";
      style.height =  "100%";
      style.alignContent = "center";
      style.alignSelf = "center";
      style.textAlign = "center";
    }
    return style;
  }

  render() {
    const { botones, mobile, ocultar } = this.props;
    return (
      <nav
        style={{
          display: mobile ? undefined : "flex",
          flexGrow: 1,
          justifySelf: mobile?"flex-start":"flex-end",
          justifyContent: "space-between",
          justifyItems: "center",
          backgroundColor: mobile?"#ffffff":"transparent",
          height: mobile ? "100vh" : "100%",
          width: "100%",
        }}
      >
        <div ref={this.ref1}>
          <button
            className={"itemNavBar"}
            style={this.style()}
            onClick={() => {
              this.setState({ mostrarSubmenuProductos: !this.state.mostrarSubmenuProductos });
            }}
            onMouseEnter={
              mobile
                ? null
                : () => {
                    this.setState({ mostrarSubmenuProductos: true });
                  }
            }
            onMouseLeave={
              mobile
                ? null
                : () => {
                    this.setState({ mostrarSubmenuProductos: false });
                  }
            }
          >
            Productos
            <CSSTransition
              in={this.state.mostrarSubmenuProductos}
              timeout={250}
              classNames="girar"
            >
              <IcoChevron
                width={15}
                height={15}
                style={{
                  position: mobile ? "absolute" : "static",
                  right: "10%",
                }}
                stroke={mobile?"#41454c":"#FFFFFF"}
              />
            </CSSTransition>
            <CSSTransition
              in={this.state.mostrarSubmenuProductos}
              timeout={250}
              classNames="submenu"
              unmountOnExit
            >
              <SubmenuProductos mobile={mobile} ocultarMenu={ocultar} />
            </CSSTransition>
          </button>
        </div>

        <Link
          className={"itemNavBar"}
          onClick={ocultar}
          to="/puntos-de-venta.php"
          style={this.style()}
        >
          Puntos de venta
        </Link>
        <Link
          className={"itemNavBar"}
          onClick={ocultar}
          to="/atencion-al-cliente"
          style={this.style()}
        >
          Atención al cliente
        </Link>
        <div ref={this.ref2}>
          <button
            className={"itemNavBar"}
            style={this.style()}
            onClick={() => {
              this.setState({ mostrarSubmenuContacto: !this.state.mostrarSubmenuContacto });
            }}
            onMouseEnter={
              mobile
                ? null
                : () => {
                    this.setState({ mostrarSubmenuContacto: true });
                  }
            }
            onMouseLeave={
              mobile
                ? null
                : () => {
                    this.setState({ mostrarSubmenuContacto: false });
                  }
            }
          >
            Contacto
            <CSSTransition
              in={this.state.mostrarSubmenuContacto}
              timeout={250}
              classNames="girar"
            >
              <IcoChevron
                width={15}
                height={15}
                style={{
                  position: mobile ? "absolute" : "static",
                  right: "10%",
                }}
                stroke={mobile?"#41454c":"#FFFFFF"}
              />
            </CSSTransition>
            <CSSTransition
              in={this.state.mostrarSubmenuContacto}
              timeout={250}
              classNames="submenu"
              unmountOnExit
            >
              <SubmenuContacto mobile={mobile} ocultarMenu={ocultar} />
              
            </CSSTransition>
          </button>
        </div>

        {/*<Link
          className={"itemNavBar"}
          onClick={ocultar}
          to="/concurso"
          style={this.style()}
        >
          Concurso
        </Link>*/}
        {mobile && <div style= {{width: "80%", marginLeft: "auto", marginRight: "auto", height: 1, backgroundColor: "#41454c"}}/>}
        {mobile ? (
          <AccesoMobile
            nivelLogin={this.state.nivelLogin}
            botones={botones}
            ocultar={ocultar}
          />
        ) : (
          <AccesoDesktop
            nivelLogin={this.state.nivelLogin}
            botones={botones}
            ocultar={ocultar}
          />
        )}
        
      </nav>
    );
  }
}

/*
<Link
  className={"itemNavBar"}
  onClick={ocultar}
  to="/servicioTecnico"
  style={this.style("64.06%")}
>
  SERVICIO TÉCNICO
</Link>
        */

export default Menu;
