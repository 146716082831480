import React, { Component } from "react";
import { Link } from "react-router-dom";

const SubmenuProductos = ({ mobile, ocultarMenu }) => {

  return (
    <div 
    style={mobile?
    {
      position: "relative",
      left: "5%",
      top: 20,
      width: "auto",
      background: "#ffffff",
    }:
    {
      position: "absolute",
      top: 80,
      width: 707,
      height: 375,
      display: "flex",
      justifyContent: "space-between",
      background: "#FFFFFF",
      paddingLeft: 50,
      paddingTop: 20,
      paddingBottom: 20,
      paddingRight: 40,
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
    }}>
      <div>
      <Link
          onClick={ocultarMenu}
          to="/calefactores"
        >
          <div className="titulo_categoria_submenu">
          Calefacción
        </div>
        </Link>
        
        <Link
          onClick={ocultarMenu}
          to="/calefactores?energia=A gas"
        >
          <div className="subtitulo_categoria_submenu">
          A gas
        </div>
        </Link>
        
        <Link
          onClick={ocultarMenu}
          to="/calefactores?linea=Peltre Acero"
        >
          <div className="categoria_submenu">Peltre acero</div>
        </Link>
        <Link
          onClick={ocultarMenu}
          to="/calefactores/convector-coppens-garrafero-estufa-convectormovil-gaslicuado-5000kcal"
        >
          <div className="categoria_submenu">Convector móvil 5.0</div>
        </Link>
        <Link
          onClick={ocultarMenu}
          to="/calefactores?linea=Cromo Clásico"
        >
          <div className="categoria_submenu">Cromo clásico</div>
        </Link>
        <Link
          onClick={ocultarMenu}
          to="/calefactores?linea=Cromo Fundición"
        >
          <div className="categoria_submenu">Cromo fundición</div>
        </Link>

        <div style={{width: "100%", height: 20}}/>
        <Link
          onClick={ocultarMenu}
          to="/calefactores?energia=Eléctricos"
        >
          <div className="subtitulo_categoria_submenu">
          Eléctricos
        </div>
        </Link>
        <Link
          onClick={ocultarMenu}
          to="/calefactores/convector-coppens-vytro-electrico-2000watts"
        >
          <div className="categoria_submenu">Convector vytro</div>
        </Link>
      </div>
      <div>
        <Link
          onClick={ocultarMenu}
          to="/termotanques"
        >
          <div className="titulo_categoria_submenu">
            Agua caliente
          </div>
        </Link>
        <Link
          onClick={ocultarMenu}
          to="/termotanques?energia=A gas"
        >
          <div className="subtitulo_categoria_submenu">
          Termotanque a gas
        </div>
        </Link>
        <Link
          onClick={ocultarMenu}
          to="/termotanques?linea=Recuperación Simultánea"
        >
          <div className="categoria_submenu">Recuperación simultánea</div>
        </Link>
        <Link
          onClick={ocultarMenu}
          to="/termotanques?linea=Alta Recuperación"
        >
          <div className="categoria_submenu">Alta recuperación GMAX</div>
        </Link>
        <div style={{width: "100%", height: 20}}/>
        <Link
          onClick={ocultarMenu}
          to="/termotanques?energia=Eléctricos"
        >
          <div className="subtitulo_categoria_submenu">
          Termotanque eléctrico
        </div>
        </Link>
        <Link
          onClick={ocultarMenu}
          to="/termotanques?linea=Ecobianco"
        >
          <div className="categoria_submenu">Ecobianco</div>
        </Link>
      </div>
      <div>
        <Link
          onClick={ocultarMenu}
          to="/hornos"
        >
          <div className="titulo_categoria_submenu"> 
          Cocina
        </div>
        </Link>
        <Link
          onClick={ocultarMenu}
          to="/hornos"
        >
          <div className="subtitulo_categoria_submenu">Hornos</div>
        </Link>
      </div>
    </div>
  );
};

export default SubmenuProductos;
