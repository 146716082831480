import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../../res/iconos/logoNav.svg";
import { ReactComponent as IcoMenu } from "../../../res/iconos/menu.svg";
import Menu from "./menu.jsx";
import { CSSTransition } from "react-transition-group";

class NavBar extends Component {
  state = { mostrarMenu: false, nivelLogin: this.props.nivelLogin };

  componentDidUpdate(prevProps) {
    if (prevProps.nivelLogin != this.props.nivelLogin) {
      this.setState({ nivelLogin: this.props.nivelLogin });
    }
  }

  ocultarMenu() {
    this.setState({ mostrarMenu: false });
  }

  render() {
    const mobile = window.innerWidth < 1280;

    return (
      <div
        style={{
          width: "100%",
          top: 0,
          position: "sticky",
          background: "#9d1c21",
          height: 80,
          zIndex: 100,
        }}
      >
        <div style={mobile?{height: "100%"}:{paddingLeft: "10%", paddingRight: "10%", height: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", gap: "14%"}}>
          <Link
            to="/"
            onClick={() => {
              this.setState({ mostrarMenu: false });
            }}
          >
            <Logo style={mobile?{height: "100%", width: 172, display: "inline", marginLeft: "10%"}:undefined} width={172} height={29} />
          </Link>
          {mobile && (
            <React.Fragment>
              <div
                onClick={() => {
                  this.setState({ mostrarMenu: !this.state.mostrarMenu });
                }}
                style={{
                  display: "flex",
                  float: "right",
                  marginRight: "10%",
                  height: "100%",
                  position: "relative"
                }}
              >
                <IcoMenu
                  width={35}
                />
              </div>
              <CSSTransition
                in={this.state.mostrarMenu}
                timeout={100}
                classNames="opacidad"
                unmountOnExit
              >
                <Menu
                  mobile={mobile}
                  ocultar={this.ocultarMenu.bind(this)}
                  nivelLogin={this.state.nivelLogin}
                  botones={this.props.botones}
                />
              </CSSTransition>
            </React.Fragment>
          )}
          {!mobile && (
            <Menu
              mobile={mobile}
              ocultar={this.ocultarMenu.bind(this)}
              nivelLogin={this.state.nivelLogin}
              botones={this.props.botones}
            />
          )}
        </div>
      </div>
    );
  }
}

export default NavBar;
