import React, { Component } from "react";

class Politica extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div style={{width: "80%", marginLeft: "auto", marginRight: "auto"}}>
        <div style={{ width: "100%", height: "7vw" }} />
        <div style={{width: "70%", marginLeft: "auto", marginRight: "auto", justifyItems: "center"}}>
          <h1 className="politica_titulo_top">POLÍTICA DE PRIVACIDAD</h1>
          <div style={{width: "100%", display: "flex", justifyContent: "center", marginBottom: 60}}>
            <img src={require("../../res/iconos/politica.png")}
              style={{
                position: "relative",
                objectFit: "cover",
                objectPosition: "center",
                height: "155",
              }} />
          </div>
          <p className="politica_texto_top">Al proporcionar tus datos para solicitar una reparación en garantía u otros formularios a través de nuestro sitio web, estás consintiendo la recopilación y el tratamiento de tu información personal. 
          Por este motivo, te ofrecemos la siguiente información para que estés al tanto de cómo vamos a procesar tus datos y los derechos que tienes para controlarlos.</p>
          <div style={{width: "100%", height: 30}}/>
        </div>
        <div className="politica_separador"/>
        <h2 className="politica_titulo">¿Quién es el responsable del tratamiento de la Información Personal?</h2>
        <p className="politica_texto">Los datos quedan registrados en una base de datos propiedad de COPPENS S.A, con domicilio en Calle 5 entre 2 y 4 - Parque Industrial General Savio, Ruta 88 Km. 6,5, Batán, Mar del Plata, Bs.As., Argentina (7601).En Coppens, valoramos la privacidad y la seguridad de tus datos personales. Esta Política de Privacidad establece cómo recopilamos, utilizamos, protegemos y compartimos la información que nos proporcionas a través de nuestro sitio web, de conformidad con la Ley Nº 25.326 de Protección de Datos Personales de la República Argentina.</p>
        <div className="politica_separador"/>
        <h2 className="politica_titulo">Recopilación de Información</h2>
        <p className="politica_texto">Recopilamos información personal que nos proporcionas voluntariamente al registrarte en nuestro sitio web, o al completar formularios en línea. Esta información puede incluir tu nombre, dirección de correo electrónico, dirección postal, número de teléfono y detalles de pago. Además, recopilamos automáticamente cierta información sobre tu dispositivo y tu actividad en nuestro sitio web utilizando cookies y otras tecnologías de seguimiento.  La inexactitud o falsedad de los datos personales que proporciones podría causar daños a tus derechos o intereses, los nuestros o de terceros.</p>
        <div className="politica_separador"/>
        <h2 className="politica_titulo">Uso de la Información</h2>
        <p className="politica_texto">Utilizamos la información recopilada para procesar tus solicitudes, comunicarnos contigo, proporcionarte información y actualizaciones sobre nuestros productos y servicios; y para fines publicitarios. También podemos utilizar tu información para mejorar y personalizar tu experiencia en nuestro sitio web. En caso de que necesitemos utilizar tus datos para cualquier otro propósito que no sea compatible con los mencionados, te informaremos previamente antes de proceder con su tratamiento.</p>
        <div className="politica_separador"/>
        <h2 className="politica_titulo">¿Cómo compartimos la Información Personal?</h2>
        <p className="politica_texto">No vendemos, intercambiamos ni transferimos tus datos personales a terceros sin tu consentimiento, excepto en los casos dispuestos en la presente Declaración de Privacidad.
En este contexto, te  informamos que el proveedor de la plataforma y tecnología que respalda nuestro sitio web actuará como un tercero encargado del tratamiento de datos. Este proveedor se compromete a tratar tus datos de acuerdo con nuestra Declaración de Privacidad. 
Además, tus Datos Personales pueden ser transferidos a otros terceros que actúen en nuestro nombre para procesarlos de acuerdo con los fines para los que fueron recopilados originalmente o para otros fines legales, como la prestación de servicios (como logística), análisis del sitio web, marketing, gestión de datos o soporte técnico. Estos terceros han suscrito un contrato con nosotros para utilizar exclusivamente los datos personales para los fines acordados y no vender ni divulgar tus datos personales a otros terceros, salvo que lo exija la legislación, lo permitamos nosotros o se indique en nuestra Declaración de Privacidad.</p>
        <div className="politica_separador"/>
        <h2 className="politica_titulo">Tus Derechos</h2>
        <p className="politica_texto">De acuerdo con la Ley Nº 25.326, tienes derecho a acceder, corregir y eliminar tus datos personales, así como a oponerte al procesamiento de los mismos. Puedes ejercer estos derechos enviándo un correo electrónico a:</p>
        <div className="politica_espacio_parrafo" />  
        <p className="politica_texto">La DIRECCIÓN NACIONAL DE PROTECCIÓN DE DATOS PERSONALES. Es el órgano de Control de la Ley Nº 25.326,  y tiene la atribución de atender las denuncias y reclamos que se interpongan con relación al incumplimiento de las normas sobre protección de datos personales.</p>
        <div className="politica_separador"/>
        <h2 className="politica_titulo">Cambios en la Política de Privacidad</h2>
        <p className="politica_texto">Nos reservamos el derecho de modificar esta Política de Privacidad en cualquier momento, de acuerdo con la Ley Nº 25.326. Cualquier cambio será efectivo inmediatamente después de la publicación en nuestro sitio web. Te recomendamos que revises periódicamente esta página para estar al tanto de cualquier cambio.</p>
        <div style={{width: "100%", height: "7vw"}}/>
      </div>
    );
  }
}

export default Politica;
