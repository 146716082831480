import React, { Component } from "react";
import Pregunta from "./pregunta.jsx";

class PreguntasFrecuentes extends Component {
  state = { categorias: [], categoriaSeleccionada: "" };

  componentDidMount() {
    window.scrollTo(0, 0);
    fetch("/api/preguntasFrecuentes")
      .then((res) => res.json())
      .then((data) => {
        let categorias = [];
        let i = 0;
        while (i < data.length) {
          const index = categorias.findIndex((c) => c.cat == data[i].cat);
          if (index == -1) {
            const categoria = {
              cat: data[i].cat,
              preguntas: [{pregunta: data[i].pregunta, respuesta: data[i].respuesta, imagen: data[i].imagen}]
            }
            categorias.push(categoria);
          } else {
            categorias[index].preguntas.push({pregunta: data[i].pregunta, respuesta: data[i].respuesta, imagen: data[i].imagen});
          }
          i++;
        }
        this.setState({ categorias: categorias, categoriaSeleccionada: categorias[0]?.cat});
      });
  }

  render() {
    const mobile =
      window.innerWidth < 1000;
    return (
      <React.Fragment>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: 300,
          }}
        >
          <img
            src={require("../../res/banners/pf_" + (mobile ? "mobile":"desktop" ) + ".jpg")}
            style={{
              position: "relative",
              objectFit: "cover",
              objectPosition: "center",
              width: "100%",
              height: 300,
              zIndex: -1,
            }}
          />
          <img
            src={require("../../res/banners/pf_titulo.png")}
            style={{
              position: "absolute",
              objectFit: "cover",
              objectPosition: "left",
              width: "80%",
              maxWidth: 466,
              height: "auto",
              top: "25%",
              left: "10%",
              zIndex: 0,
            }}
          />
        </div>
        <h1 style={{position: "absolute", width: 1, height: 1, opacity: 0, overflow: "hidden"}}>Preguntas frecuentes</h1>
 
        <div
          style={{
            position: "relative",
            width: "80%",
            marginLeft: "auto",
            marginRight: "auto"
          }}
        >
          <div style={{width: "100%", height: "6vw"}}/>

          <div style={{display: "flex", flexDirection: window.innerWidth < (this.state.categorias.length * 200 / 0.8)?"column":"row", gap: 0, flexWrap: "wrap", justifyContent: "center", alignContent: "center"}}>
            {this.state.categorias.map(c => 
              <button 
                className="pf_categoria"
                onClick={() => this.setState({categoriaSeleccionada: c.cat})} 
                disabled={c.cat == this.state.categoriaSeleccionada}>
                  {c.cat}
              </button>
            )}
          </div>
          
          <div style={{width: "100%", height: "4vw"}}/>

          {this.state.categorias.find((c) => c.cat == this.state.categoriaSeleccionada)?.preguntas.map((e, i) => (
            <Pregunta key={`pregunta_${this.state.categoriaSeleccionada}_${i}`} pregunta={e.pregunta} respuesta={e.respuesta} imagen={e.imagen} />
          ))}
        </div>
        <div style={{width: "100%", height: "7vw"}}/>
      </React.Fragment>
    );
  }
}

export default PreguntasFrecuentes;
