import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as IcoLogout } from "../../../res/iconos/logout.svg";
import { createRef } from "react";
import { CSSTransition } from "react-transition-group";
import { ReactComponent as IcoChevron } from "../../../res/iconos/chevron.svg";
import SubmenuAcceso from "./submenuAcceso";

class AccesoMobile extends Component {
  state = { nivel: this.props.nivelLogin, mostrarSubmenu: false };

  //constructor, componentDidMount, componentWillUnmount y handleClickOutside son para ocultar el submenu tocando fuera de el
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.nivelLogin != this.props.nivelLogin) {
      this.setState({ nivel: this.props.nivelLogin });
    }
  }

  style() {
    let style = {
      background: "transparent",
      border: "none",
    };
    style.position = "relative";
    style.display = "block";
    style.width = "100%";
    style.padding = "30px 10%";
    style.textAlign = "left";
    style.color = "#41454c";
    style.fontWeight = 700;
    return style;
  }

  render() {
    let retorno;
    switch (this.state.nivel) {
      case 0:
        retorno = (
          <div>
            <button 
              onClick={() => {
                this.setState({ mostrarSubmenu: !this.state.mostrarSubmenu });
              }}
              className={"itemNavBar"}
              style={this.style()}>
              Ingresar
              <CSSTransition
                in={this.state.mostrarSubmenu}
                timeout={250}
                classNames="girar"
              >
                <IcoChevron
                  width={15}
                  height={15}
                  style={{
                    position: "absolute",
                    right: "10%",
                  }}
                  stroke="#41454c"
                />
              </CSSTransition>
              <CSSTransition
              in={this.state.mostrarSubmenu}
              timeout={250}
              classNames="submenu"
              unmountOnExit
            >
              <SubmenuAcceso mobile={true} ocultarMenu={this.props.ocultar} />
            </CSSTransition>
            </button>
              
            
          </div>
        );
        break;

      default:
        retorno = (
          <>
            <Link
              to={this.state.nivel == 3 ? "/admin" : "/comerciantes"}
              className="itemNavBar"
              onClick={() => {
                this.props.ocultar();
              }}
              style={this.style()}
            >
              {this.state.nivel == 3 ? "Administración" : "Comerciantes"}
            </Link>
            <button
              className="itemNavBar"
              style={{
                border: "none",
                background: "transparent",
                marginLeft: 20,
              }}
              onClick={() => {
                this.props.ocultar();
                this.props.botones.cerrarSesion();
              }}
            >
              Salir
              <IcoLogout
                style={{ marginLeft: 5 }}
                fill="#FFFFFF"
                width={30}
                height={30}
              />
            </button>
          </>
        );
        break;
    }
    return retorno;
  }
}

export default AccesoMobile;
