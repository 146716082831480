import React, { Component } from "react";
import calcularPx from "../general/calcularPx";
import Producto from "./producto.jsx";
import Filtros from "./filtros.jsx";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { ReactComponent as IcoFiltros } from "../../res/iconos/filtros.svg";
import { ReactComponent as IcoCerrar } from "../../res/iconos/cerrar.svg";

function withRouterAndNavigate(Component) {
  function ComponentWithRouterAndNavigate(props) {
    let params = useParams();
    let navigate = useNavigate();
    let location = useLocation();
    return (
      <Component
        {...props}
        params={params}
        navigate={navigate}
        location={location}
      />
    );
  }
  return ComponentWithRouterAndNavigate;
}

class Productos extends Component {
  state = {
    categoria: this.props.params.categoria,
    productos: [],
    productosFiltrados: [],
    filtros: this.getFiltros(
      this.props.params.categoria
    ),
    filtrosAplicados: [],
    productoDetalle: null,
    mostrarFiltros: false,
    cargo: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getProductos();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.params.categoria !== this.props.params.categoria) {
      this.setState(
        {
          categoria: this.props.params.categoria,
          filtros: [],
          filtrosAplicados: [],
          productos: [],
          productosFiltrados: [],
          cargo: false,
        },
        () => {
          this.setState(
            {
              productos: [],
              productosFiltrados: [],
              filtros: [],
              cargo: false,
            },
            () => this.getProductos()
          );
        }
      );
    } else {
      if (prevProps.location.search !== this.props.location.search) {
        this.setState(
          {
            filtrosAplicados: [],
            cargo: false,
          },() => {
            this.getProductos()
          })
      }
    }
  }

  getProductos() {
    fetch("/api/productos/?linea=" + this.state.categoria)
      .then((res) => res.json())
      .then((data) => {
        let queryParams = new URLSearchParams(this.props.location.search);
        this.setState(
          { filtros: this.getFiltros(this.props.params.categoria) },
          () => {
            let filtrosQuery = this.getFiltrosInicialesAdicionales(
              queryParams
            );

            let productosFiltrados = this.getProductosFiltrados(
              data,
              filtrosQuery
            );

            this.setState({
              productos: data,
              productosFiltrados: productosFiltrados,
              cargo: true,
            });
          }
        );
      });
  }

  resetear(callback) {
    let queryParams = new URLSearchParams(this.props.location.search);
    this.setState(
      { filtros: this.getFiltros(this.props.params.categoria) },
      () => {
        let filtrosCustom = this.getFiltrosAplicadosIniciales(
          this.props.params.categoria,
          queryParams
        );

        this.setState({
          filtrosAplicados: filtrosCustom,
          productosFiltrados: this.state.productos,
          cargo: true,
        }, callback());
      }
    );
  }

  getFiltrosInicialesAdicionales(queryParams) {
    let filtros = [];
    for (const [key, value] of queryParams) {
      let f = this.state.filtros.find((f) => f.campo === key);
      if (f) {
        let o = f.opciones.find((o) => o.nombre === value);
        if (o) {
          o.checked = true;
          filtros = this.agregarAFiltrosAplicados(filtros, f, o);
        }
      }
    }
    return filtros;
  }

  getFiltrosAplicadosIniciales(categoria, queryParams) {
    let filtrosCustom = [];
    switch (categoria) {
      case "calefactores_recomendados":
        let kcal = queryParams.get("kcal");
        let sce = queryParams.get("sce");
        let tb = queryParams.get("tb");
        let tbu = queryParams.get("tbu");
        filtrosCustom = [
          {
            campo: "kcal",
            opcion: {
              valorMin: parseInt(kcal),
              valorMax: 99999,
              checked: true,
            },
          },
          {
            campo: "sce",
            opcion: {
              valorMin: 0,
              valorMax: sce === "true" ? 1 : 0,
              checked: true,
            },
          },
          {
            campo: "tiraje",
            opcion: {
              valorMin: 0,
              valorMax: tb === "false" ? 0 : 3,
              checked: true,
            },
          },
          {
            campo: "tiraje",
            opcion: {
              valorMin: tb === "false" ? 2 : 0,
              valorMax: 3,
              checked: true,
            },
          },
          {
            campo: "tbu",
            opcion: {
              valorMin: 0,
              valorMax: tbu === "true" ? 1 : 0,
              checked: true,
            },
          },
        ];
        break;
      case "termotanques_recomendados":
        let litros = parseInt(queryParams.get("litros"));
        let e = queryParams.get("e");
        let g = queryParams.get("g");
        filtrosCustom = [
          {
            campo: "capacidadLt",
            opcion: {
              valorMin: litros,
              valorMax: 99999,
              checked: true,
            },
          },
          {
            campo: "energia",
            opcion: {
              valorMin: e === "true" ? "e" : "g",
              valorMax: g === "true" ? "g" : "e",
              checked: true,
            },
          },
        ];
        break;
      default:
        filtrosCustom = [];
    }

    let filtrosAplicadosIniciales = [];
    filtrosCustom.forEach((f) => {
      filtrosAplicadosIniciales = this.agregarAFiltrosAplicados(
        filtrosAplicadosIniciales,
        f,
        f.opcion
      );
    });

    return filtrosAplicadosIniciales;
  }

  getFiltros(categoria) {
    let filtros = [];
    switch (categoria) {
      case "calefactores":
        filtros = [
          {
            titulo: "Tipo de energía",
            campo: "energia",
            mostrar: true,
            opciones: [
              {
                nombre: "A gas",
                valorMin: "Garrafa de 10kg",
                valorMax: "Multigas",
                mostrar: true,
                checked: false,
              },
              {
                nombre: "Eléctricos",
                valorMin: "Eléctrico",
                valorMax: "Eléctrico",
                mostrar: true,
                checked: false,
              },
            ],
          },
          {
            titulo: "Modelo",
            campo: "linea",
            mostrar: true,
            opciones: [
              {
                nombre: "Peltre Acero",
                valorMin: "Peltre Acero",
                valorMax: "Peltre Acero",
                mostrar: true,
                checked: false,
              },
              {
                nombre: "Convector Móvil",
                valorMin: "Convector Móvil",
                valorMax: "Convector Móvil",
                mostrar: true,
                checked: false,
              },
              {
                nombre: "Cromo Clásico",
                valorMin: "Cromo Clásico",
                valorMax: "Cromo Clásico",
                mostrar: true,
                checked: false,
              },
              {
                nombre: "Cromo Fundición",
                valorMin: "Cromo Fundición",
                valorMax: "Cromo Fundición",
                mostrar: true,
                checked: false,
              },
              {
                nombre: "Convector Vytro",
                valorMin: "Convector Vytro",
                valorMax: "Convector Vytro",
                mostrar: true,
                checked: false,
              },
            ],
          },
          {
            titulo: "Tipo de tiraje",
            campo: "tiraje",
            mostrar: true,
            opciones: [
              {
                nombre: "Tiro Balanceado",
                valorMin: 1,
                valorMax: 3,
                mostrar: true,
                checked: false,
              },
              {
                nombre: 'Tiro Balanceado "U"',
                valorMin: 2,
                valorMax: 3,
                mostrar: true,
                checked: false,
              },
              {
                nombre: 'Tiro Natural',
                valorMin: 4,
                valorMax: 4,
                mostrar: true,
                checked: false,
              },
              {
                nombre: "Sin tiraje",
                valorMin: 0,
                valorMax: 0,
                mostrar: true,
                checked: false,
              },
            ],
          },
          {
            titulo: "Calorias",
            campo: "kcal",
            mostrar: true,
            opciones: [
              {
                nombre: "2500 - 3000 Cal.",
                valorMin: 2500,
                valorMax: 3000,
                mostrar: true,
                checked: false,
              },
              {
                nombre: "4000 - 5000 Cal.",
                valorMin: 4000,
                valorMax: 5000,
                mostrar: true,
                checked: false,
              },
              {
                nombre: "+6000 Cal.",
                valorMin: 6000,
                valorMax: 99999,
                mostrar: true,
                checked: false,
              },
            ],
          },
          {
            titulo: "Superficie a calefaccionar",
            campo: "espacioCalefaccionM2",
            mostrar: true,
            opciones: [
              {
                nombre: "20 - 25 m2",
                valorMin: 20,
                valorMax: 25,
                mostrar: true,
                checked: false,
              },
              {
                nombre: "30 - 40 m3",
                valorMin: 30,
                valorMax: 40,
                mostrar: true,
                checked: false,
              },
              {
                nombre: "+ 40 m3",
                valorMin: 40,
                valorMax: 999,
                mostrar: true,
                checked: false,
              },
            ],
          },
        ];
        break;
      case "hornos":
        filtros = [
          {
            titulo: "Capacidad",
            campo: "capacidadLt",
            mostrar: true,
            opciones: [
              {
                nombre: "42 L",
                valorMin: 42,
                valorMax: 42,
                mostrar: true,
                checked: false,
              },
              {
                nombre: "57 L",
                valorMin: 57,
                valorMax: 57,
                mostrar: true,
                checked: false,
              },
            ],
          },
          {
            titulo: "Potencia",
            campo: "potenciaWatts",
            mostrar: true,
            opciones: [
              {
                nombre: "1500 W",
                valorMin: 1500,
                valorMax: 1500,
                mostrar: true,
                checked: false,
              },
              {
                nombre: "2000 W",
                valorMin: 2000,
                valorMax: 2000,
                mostrar: true,
                checked: false,
              },
            ],
          },
          {
            titulo: "Peso",
            campo: "pesoKg",
            mostrar: true,
            opciones: [
              {
                nombre: "7 Kg",
                valorMin: 7,
                valorMax: 7,
                mostrar: true,
                checked: false,
              },
              {
                nombre: "8.7 Kg",
                valorMin: 8.7,
                valorMax: 8.7,
                mostrar: true,
                checked: false,
              },
            ],
          },
          {
            titulo: "Bandeja portamigas",
            campo: "portamigas",
            mostrar: true,
            opciones: [
              {
                nombre: "Si",
                valorMin: 1,
                valorMax: 1,
                mostrar: true,
                checked: false,
              },
              {
                nombre: "No",
                valorMin: 0,
                valorMax: 0,
                mostrar: true,
                checked: false,
              },
            ],
          },
          {
            titulo: "Niveles de cocción",
            campo: "nivelesCoccion",
            mostrar: true,
            opciones: [
              {
                nombre: "3",
                valorMin: 3,
                valorMax: 3,
                mostrar: true,
                checked: false,
              },
              {
                nombre: "5",
                valorMin: 5,
                valorMax: 5,
                mostrar: true,
                checked: false,
              },
            ],
          },
          {
            titulo: "Posiciones de bandeja",
            campo: "posicionesBandeja",
            mostrar: true,
            opciones: [
              {
                nombre: "3",
                valorMin: 3,
                valorMax: 3,
                mostrar: true,
                checked: false,
              },
              {
                nombre: "4",
                valorMin: 4,
                valorMax: 4,
                mostrar: true,
                checked: false,
              },
            ],
          },
        ];
        break;
      case "termotanques":
        filtros = [
          {
            titulo: "Tipo de energía",
            campo: "energia",
            mostrar: true,
            opciones: [
              {
                nombre: "A gas",
                valorMin: "g",
                valorMax: "g",
                mostrar: true,
                checked: false,
              },
              {
                nombre: "Eléctricos",
                valorMin: "e",
                valorMax: "e",
                mostrar: true,
                checked: false,
              },
            ],
          },
          {
            titulo: "Modelo",
            campo: "linea",
            mostrar: true,
            opciones: [
              {
                nombre: "Recuperación Simultánea",
                valorMin: "Recuperación Simultánea",
                valorMax: "Recuperación Simultánea",
                mostrar: true,
                checked: false,
              },
              {
                nombre: "Alta Recuperación",
                valorMin: "Alta Recuperación",
                valorMax: "Alta Recuperación",
                mostrar: true,
                checked: false,
              },
              {
                nombre: "Ecobianco",
                valorMin: "Ecobianco",
                valorMax: "Ecobianco",
                mostrar: true,
                checked: false,
              },
            ],
          },
          {
            titulo: "Conexión de agua",
            campo: "conexionAgua",
            mostrar: true,
            opciones: [
              {
                nombre: "Superior",
                valorMin: "1",
                valorMax: "2",
                mostrar: true,
                checked: false,
              },
              {
                nombre: "Inferior",
                valorMin: "0",
                valorMax: "1",
                mostrar: true,
                checked: false,
              },
            ],
          },
          {
            titulo: "Instalación",
            campo: "instalacion",
            mostrar: true,
            opciones: [
              {
                nombre: "Apoyado",
                valorMin: 0,
                valorMax: 1,
                mostrar: true,
                checked: false,
              },
              {
                nombre: "Colgado",
                valorMin: 1,
                valorMax: 2,
                mostrar: true,
                checked: false,
              },
            ],
          },
          {
            titulo: "Litros",
            campo: "capacidadLt",
            mostrar: true,
            opciones: [
              {
                nombre: "23-50 L",
                valorMin: 23,
                valorMax: 50,
                mostrar: true,
                checked: false,
              },
              {
                nombre: "65-95 L",
                valorMin: 65,
                valorMax: 95,
                mostrar: true,
                checked: false,
              },
              {
                nombre: "+120 L",
                valorMin: 120,
                valorMax: 999,
                mostrar: true,
                checked: false,
              }
            ],
          },
        ];
        break;
        default:
        filtros = [];
        break;
    }
    return filtros;
  }

  ocultarFiltros() {
    this.setState({ mostrarFiltros: false });
  }

  cambiarFiltro(f, o) {
    let filtros = [...this.state.filtros];
    let opcion = filtros.find((fs) => f === fs).opciones.find((os) => o === os);
    opcion.checked = !opcion.checked;

    let filtrosAplicados = this.agregarAFiltrosAplicados(
      [...this.state.filtrosAplicados],
      f,
      opcion
    );
    this.setState(
      {
        filtros: filtros,
        filtrosAplicados: filtrosAplicados,
        productosFiltrados: [],
        cargo: false,
      },
      () =>
        this.setState({
          productosFiltrados: this.getProductosFiltrados(
            this.state.productos,
            filtrosAplicados
          ),
          cargo: true,
        })
    );
  }

  agregarAFiltrosAplicados(filtrosAplicados, f, opcion) {
    let indice = filtrosAplicados.findIndex((fa) => fa.campo === f.campo);
    if (opcion.checked) {
      if (indice === -1) {
        filtrosAplicados.push({
          campo: f.campo,
          valores: [{ min: opcion.valorMin, max: opcion.valorMax }],
        });
      } else {
        filtrosAplicados[indice].valores.push({
          min: opcion.valorMin,
          max: opcion.valorMax,
        });
      }
    } else {
      if (indice !== -1) {
        filtrosAplicados[indice].valores = filtrosAplicados[
          indice
        ].valores.filter((v) => {
          return v.min !== opcion.valorMin || v.max !== opcion.valorMax;
        });
        if (filtrosAplicados[indice].valores.length === 0) {
          filtrosAplicados.splice(indice, 1);
        }
      }
    }
    return filtrosAplicados;
  }

  getProductosFiltrados(productos, filtrosAplicados) {
    let productosFiltradosTotal = [...productos];
    filtrosAplicados.forEach((c) => {
      let productosFiltradosCampo = [];
      c.valores.forEach((v) => {
        let productosFiltrados = productosFiltradosTotal.filter((p) => {
          return v.min <= p[c.campo] && p[c.campo] <= v.max;
        });
        productosFiltradosCampo = productosFiltradosCampo.concat(
          productosFiltrados.filter((p) => !productosFiltradosCampo.includes(p))
        );
      });
      productosFiltradosTotal = productosFiltradosTotal.filter((p) =>
        productosFiltradosCampo.includes(p)
      );
    });

    return productosFiltradosTotal;
  }

  render() {
    const mobile = window.innerWidth*0.8 < 294*2 + 245;
    const anchoFiltros = mobile ? 0 : 245;
    const cantColumnas =
      Math.floor((window.innerWidth*0.8 - anchoFiltros) /
      294);
    let banner;
    let porcentaje;
    let linkBanner;
    let textoBanner;
    switch (this.state.categoria) {
      case "calefactores":
        banner = "calefaccion";
        textoBanner = "¡Descubrí tu convector ideal!";
        linkBanner = "/calculo-termico"
        porcentaje = "30%";
        break;
      case "hornos":
        banner = "cocina";
        porcentaje = "30%";
        break;
      case "termotanques":
        banner = "agua_caliente";
        porcentaje = "50%";
        textoBanner = "¡Encontrá tu termotanque ideal!";
        linkBanner = "/calculo-agua"
        break;
      default:
        banner = "calefaccion";
        porcentaje = "30%";
        textoBanner = "¡Descubrí tu convector ideal!";
        linkBanner = "/calculo-termico"
        break;
    }

    return (
      <React.Fragment>        
        {mobile && this.state.mostrarFiltros && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "calc(100% - 80px)",
            }}
          >
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                zIndex: 50,
              }}
            />
            <div
              style={{
                position: "fixed",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                paddingTop: "7%",
                width: "100%",
                height: "100%",
                zIndex: 101,
              }}
            >
              <div
                style={{
                  position: "relative",
                  width: "80%",
                  height: "auto",
                  maxHeight: "90%",
                  background: "white",
                  padding: "5%",
                  zIndex: 51,
                }}
              >
                <Filtros
                  filtros={this.state.filtros}
                  quitar={(callback) => {this.resetear(callback)}}
                  cambiarFiltro={(f, o) => this.cambiarFiltro(f, o)}
                />
                <button
                  onClick={() => {
                    this.ocultarFiltros();
                  }}
                  style={{
                    position: "absolute",
                    top: -22,
                    right: -22,
                    background: "transparent",
                    border: "none",
                  }}
                >
                  <IcoCerrar width={44} height={44} />
                </button>
              </div>
            </div>
          </div>
        )}

        <div
          style={{
            position: "relative",
            width: "100%",
            height: 300,
          }}
        >
          <img
            src={require("../../res/banners/" + banner + (mobile ? "_mobile":"_desktop" ) + ".jpg")}
            style={{
              position: "relative",
              objectFit: "cover",
              objectPosition: "center",
              width: "100%",
              height: 300,
              zIndex: -1,
            }}
          />
          <img
            src={require("../../res/banners/" + banner + "_titulo.png")}
            style={{
              position: "absolute",
              objectFit: "cover",
              objectPosition: "left",
              width: porcentaje,
              maxWidth: 466,
              height: "auto",
              maxHeight: 140,
              top: 113,
              left: "10%",
              zIndex: 0,
            }}
          />
        </div>
        {linkBanner && 
        <div className="productos_banner">
        <h2 className="productos_banner_texto">{textoBanner}</h2>
          <Link to={linkBanner}>
            <h3 className="productos_banner_boton">Ir al cálculo</h3>
          </Link>
        </div>
        }
        <h1 style={{position: "absolute", width: 1, height: 1, opacity: 0, overflow: "hidden"}}>{this.state.categoria}</h1>

        <div style={{ height: "6vw" }} />

        <div
          style={{
            display: "flex",
            width: "80%",
            marginRight: "auto",
            marginLeft: "auto",
            minHeight: 860,
            flexDirection: mobile ? "column" : "row",
            gap: mobile ? 20 : 50,
            justifyContent: "space-between",
            justifyItems: "left"
          }}
        >
          {mobile && this.state.filtros.length > 0 ? (
            <button
              className="botonFiltros"
              onClick={() => {
                this.setState({ mostrarFiltros: !this.state.mostrarFiltros });
              }}
              style={{
                position: "relative",
                flexShrink: 0,
                background: "transparent",
                border: "none",
              }}
            >
              <IcoFiltros
                width={calcularPx(28.46)}
                height={calcularPx(28.46)}
              />
              <h3
                style={{
                  display: "inline",
                  position: "relative",
                  paddingLeft: calcularPx(12),
                }}
              >
                Filtros
              </h3>
            </button>
          ) : (
            <div
              style={{
                position: "relative",
                width: mobile ? "100%" : 245,
                flexShrink: 0,
              }}
            >
              {this.state.filtros.length > 0 && (
                <Filtros
                  filtros={this.state.filtros}
                  quitar={(callback) => {this.resetear(callback)}}
                  cambiarFiltro={(f, o) => this.cambiarFiltro(f, o)}
                />
              )}
            </div>
          )}

          <div>
            <div
              style={{
                display: "grid",
                flexShrink: 0,
                minWidth: cantColumnas * 270 + (cantColumnas-1)*24,
                gridAutoColumns: 270,
                gridTemplateColumns: "auto ".repeat(cantColumnas),
                gridAutoRows: 376,
                placeItems: "left",
                placeContent: mobile?"center":"left",
                width: "100%",
                gridGap: 24,
              }}
            >
              {this.state.productosFiltrados.length > 0
                ? this.state.productosFiltrados.map((e) => (
                    <Producto key={`card_producto_${e.id}`} producto={e} />
                  ))
                : this.state.cargo && (
                    <h1
                      className="textoError"
                      style={{ padding: mobile ? "10%" : 0 }}
                    >
                      No se encontraron productos con los filtros seleccionados.
                    </h1>
                  )}
            </div>
          </div>
        </div>
        <div style={{ height: "7vw" }} />
      </React.Fragment>
    );
  }
}

export default withRouterAndNavigate(Productos);
