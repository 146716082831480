import React, { Component } from "react";
import CalculoTermico from "./calculoTermico.jsx";

class Calculo extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const mobileBanner =
      window.innerWidth < 650;
    return (
      <React.Fragment>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: 250,
          }}
        >
          <img
            src={require("../../res/banners/termico_" + (mobileBanner ? "mobile":"desktop" ) + ".jpg")}
            style={{
              position: "relative",
              objectFit: "cover",
              objectPosition: "center",
              width: "100%",
              height: 250,
              zIndex: -1,
            }}
          />
        </div>

        <div style={{ height: "2vw", width: "100%" }} />
        <h1 style={{position: "absolute", width: 1, height: 1, opacity: 0, overflow: "hidden"}}>Cálculo térmico</h1>
        <h2 className="calculo_titulo">Descubrí cual es el calefactor ideal para tu ambiente</h2>
        <div style={{ height: "1vw", width: "100%" }} />

        <CalculoTermico />

        <div style={{ height: "7vw", width: "100%" }} />
      </React.Fragment>
    );
  }
}

export default Calculo;
