import React, { Component } from "react";
import calcularPx from "../general/calcularPx.js";
import InputNumber from "react-input-number";
import Producto from "../productos/producto.jsx";

class CalculoTermico extends Component {
  state = {
    dorm: false,
    ext: false,
    largo: null,
    ancho: null,
    resultado: null,
  };

  calcular() {
    const { dorm, ext, largo, ancho } = this.state;

    fetch("/api/productos/?linea=calefactores")
    .then((res) => res.json())
    .then((productos) => {
      const productosFiltrados = productos.filter((p) => {
        let devolver = true;
        if (p.espacioCalefaccionM2 < largo * ancho) {
          devolver = false;
        }
        if (ext && (!p.tb || !p.tbu)) {
          devolver = false;
        }
        if (!dorm && !ext && (p.tb || p.tbu)) {
          devolver = false;
        }
        return devolver;
      }).sort((a,b) => a.kcal - b.kcal).slice(0, 4);
      this.setState({ resultado: productosFiltrados });
    });

  }

  getTipo(tipos) {
    let retorno = "";
    let nombres = {
      sce: "sin conexión al exterior",
      tb: "de tiro balanceado",
      tbu: "de tiro balanceado en U",
    };
    Object.keys(tipos).forEach((t) => {
      if (tipos[t]) {
        retorno += " o " + nombres[t];
      }
    });
    return retorno.slice(3);
  }

  render() {
    const mobile = window.innerWidth < 785;
    const resultado = this.state.resultado;
    return (
      <React.Fragment>
        <div style={{ height: "2vw", width: "100%" }} />
        <div
          className="calculo_recuadro"
        >
          <div style={{width: "100%", display: "flex", flexWrap: "wrap", gap: 20}}>
            <div style={{flex: 1}}>
              <h1
                className="calculo_campo_titulo"
              >
                ¿Se instala en dormitorio o baño?
              </h1>
              <div
                className="calculo_radio"
                onChange={(event) =>
                  this.setState({ dorm: event.target.value, resultado: null })
                }
              >
                <input type="radio" value={true} name="dorm" />
                Si
                <input
                  type="radio"
                  value={false}
                  defaultChecked
                  name="dorm"
                  style={{ marginLeft: calcularPx(44) }}
                />
                No
              </div>
            </div>
            <div style={{flex: 1}}>
                <h1
                  className="calculo_campo_titulo"
                >
                  ¿El ambiente tiene pared al exterior?
                </h1>
                <div
                  className="calculo_radio"
                  onChange={(event) =>
                    this.setState({ ext: event.target.value, resultado: null })
                  }
                >
                  <input type="radio" value={true} name="ext" />
                  Si
                  <input
                    type="radio"
                    value={false}
                    defaultChecked
                    name="ext"
                    style={{ marginLeft: calcularPx(44) }}
                  />
                  No
                </div>
            </div>
          </div>
          <div style={{width: "100%"}}>
            <h1
              className="calculo_campo_titulo"
              style={{width: "100%"}}
            >
              ¿Qué tamaño tiene el ambiente?
            </h1>
            <div style={{width: "100%", display: "flex", flexWrap: "wrap", gap: 20}}>
              <div
                className="calculo_campo_subtitulo"
                style={{flex: 1}}
              >
                Largo (en metros)
                <InputNumber
                  className="calculo_campo"
                  min={0}
                  max={99999}
                  step={0.01}
                  value={this.state.largo}
                  onChange={(value) =>
                    this.setState({ largo: value, resultado: null })
                  }
                  enableMobileNumericKeyboard
                />
              </div>
              <div
                className="calculo_campo_subtitulo"
                style={{flex: 1}}
              >
                Ancho (en metros)
                <InputNumber
                  className="calculo_campo"
                  min={0}
                  max={99999}
                  step={0.01}
                  value={this.state.ancho}
                  onChange={(value) =>
                    this.setState({ ancho: value, resultado: null })
                  }
                  enableMobileNumericKeyboard
                />
              </div>
            </div>
          </div>
          <div style={{display: "flex", flexWrap: "wrap", gap: 20, width: "100%", alignContent: "center"}}>
            <p className="calculo_aclaracion" style={{flex: 1}}>*El espacio a calefaccionar se mide en m2 , contemplándose un altura de hasta  2.70 m.</p>
            <button
              className="calculo_boton_calefaccion"
              style={{flex: 1}}
              disabled={
                this.state.tipoVivienda == -1 ||
                !this.state.largo ||
                !this.state.ancho
              }
              onClick={() => this.calcular()}
            >
              Ver resultado
            </button>
          </div>
          
        </div>
        {resultado && (
            <>
            <div style={{height: "2vw"}}/>
            <h1 className="calculo_resultado">Resultado</h1>
            <div style={{display: "flex", flexWrap: "wrap", width: "80%", marginLeft: "auto", marginRight: "auto", gap: 24, justifyContent: "center", alignContent: "center", alignItems: "center"}}>
              {resultado?.map((p,i) => 
              <Producto key={`card_producto_${this.state.id}_${i}`} producto={p} setUrl={() => {}}/>)}
            </div>
            </>
          )}
      </React.Fragment>
    );
  }
}

export default CalculoTermico;
