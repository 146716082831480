import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import "react-multi-carousel/lib/styles.css";
import App from "./components/general/App.js";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: 'GTM-P5G8WZ7V'
};
TagManager.initialize(tagManagerArgs);

ReactDOM.render(<App />, document.getElementById("root"));
