import React, { Component } from "react";
import calcularPx from "../general/calcularPx";
import Select from "react-dropdown-select";
import { ReactComponent as IcoGarantia } from "../../res/iconos/garantia.svg";
import { Navigate } from "react-router-dom";

class RegistrarProducto extends Component {
  state = {
    mostrar: false,
    email: "",
    emailValido: false,
    nombre: "",
    nombreValido: false,
    apellido: "",
    apellidoValido: false,
    celular: "",
    celularValido: false,
    calle: "",
    calleValido: false,
    altura: "",
    alturaValido: false,
    piso: "",
    pisoValido: true,
    dpto: "",
    dptoValido: true,
    idProvincia: -1,
    provincias: [],
    provincia: "",
    provinciaValido: false,
    localidades: [],
    idLocalidad: -1,
    localidad: "",
    localidadValido: false,
    codpostal: "",
    codpostalValido: false,
    categorias: [],
    idCategoria: -1,
    categoria: "",
    categoriaValido: false,
    modelos: [],
    idModelo: -1,
    modelo: "",
    modeloValido: false,
    serie: "",
    serieValido: false,
    comercio: "",
    comercioValido: false,
    fecha_compra: "",
    fecha_compraValido: false,
    error: null,
    enviando: false,
    enviado: false,
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    const provincias = await fetch("/api/mapa/provincias").then((res) =>
      res.json()
    );
    const categorias = await fetch("/api/productos/categorias").then((res) =>
      res.json()
    );

    this.setState({ provincias: provincias, categorias: categorias });
  }

  async registrar() {
    this.setState({ error: null, enviando: true });
    const res = await fetch("/api/productos/registrar", {
      method: "post",
      body: JSON.stringify({
        email: this.state.email,
        nombre: this.state.nombre,
        apellido: this.state.apellido,
        celular: this.state.celular,
        calle: this.state.calle,
        altura: this.state.altura,
        piso: this.state.piso,
        dpto: this.state.dpto,
        idProvincia: this.state.idProvincia,
        provincia: this.state.provincia,
        idLocalidad: this.state.idLocalidad,
        localidad: this.state.localidad,
        codpostal: this.state.codpostal,
        idCategoria: this.state.idCategoria,
        categoria: this.state.categoria,
        idModelo: this.state.idModelo,
        modelo: this.state.modelo,
        serie: this.state.serie,
        comercio: this.state.comercio,
        fecha_compra: this.state.fecha_compra,
      }),
      headers: { "Content-Type": "application/json" },
    });
    const json = await res.json();
    if (json.nroRegistro !== -1) {
      this.setState({ enviando: false, enviado: true });
    } else {
      this.setState({ enviando: false, error: true, enviado: false });
    }
  }

  validarMail(email) {
    const emailRegex =
      /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
    if (!email) return false;

    if (email.length > 254) return false;

    let valid = emailRegex.test(email);
    if (!valid) return false;

    var parts = email.split("@");
    if (parts[0].length > 64) return false;

    var domainParts = parts[1].split(".");
    if (
      domainParts.some(function (part) {
        return part.length > 63;
      })
    )
      return false;

    return true;
  }

  handleChangeProvincia(value) {
    this.selectLocMethods.clearAll();
    document.getElementById("Localidad").value = "";
    if (value) {
      document.getElementById("Provincia").value = value.nombre;
      fetch("/api/mapa/localidades/?idProv=" + value.id)
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            idProvincia: value.id,
            provincia: value.nombre,
            provinciaValido: true,
            idLocalidad: -1,
            localidad: "",
            localidadValido: false,
            localidades: data,
          });
        });
    } else {
      this.setState({
        idProvincia: -1,
        provincia: "",
        provinciaValido: false,
        idLocalidad: -1,
        localidad: "",
        localidadValido: false,
        localidades: [],
      });
    }
  }

  handleChangeLocalidad(value) {
    if (value) {
      document.getElementById("Localidad").value = value.nombre;
      this.setState({
        idLocalidad: value.id,
        localidad: value.nombre,
        localidadValido: true,
      });
    }
  }

  handleChangeCategoria(value) {
    this.selectModMethods.clearAll();
    document.getElementById("Modelo").value = "";
    if (value) {
      document.getElementById("Categoría").value = value.nombre;
      fetch("/api/productos/modelos?idCategoria=" + value.idcategoria)
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            idCategoria: value.idcategoria,
            categoria: value.nombre,
            categoriaValido: true,
            idModelo: -1,
            modelo: "",
            modeloValido: false,
            modelos: data,
          });
        });
    } else {
      this.setState({
        idCategoria: -1,
        categoria: "",
        categoriaValido: false,
        idModelo: -1,
        modelo: "",
        modeloValido: false,
        modelos: [],
      });
    }
  }

  handleChangeModelo(value) {
    if (value) {
      document.getElementById("Modelo").value = value.modelo;
      this.setState({
        idModelo: value.id,
        modelo: value.modelo,
        modeloValido: true,
      });
    }
  }

  customSearch({ props, state, methods }) {
    var normalize = (function () {
      var from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç",
        to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
        mapping = {};

      for (var i = 0, j = from.length; i < j; i++)
        mapping[from.charAt(i)] = to.charAt(i);

      return function (str) {
        var ret = [];
        for (var i = 0, j = str.length; i < j; i++) {
          var c = str.charAt(i);
          if (mapping.hasOwnProperty(str.charAt(i))) ret.push(mapping[c]);
          else ret.push(c);
        }
        return ret.join("");
      };
    })();
    const regexp = new RegExp(
      normalize(methods.safeString(state.search).replace(/\s+/g, " ").trim()),
      "i"
    );
    return methods
      .sortBy()
      .filter((item) => regexp.test(normalize(item[props.searchBy])));
  }

  customContentRenderer({ props, methods }) {
    return (
      <React.Fragment>
        <div
          className="garantia_select"
          style={{ width: "100%", height: "100%" }}
        >
          <input
            type="text"
            id={props.placeholder_desactivado}
            readOnly={!props.searchable}
            onChange={(event) => methods.setSearch(event)}
            placeholder_desactivado={props.placeholder_desactivado}
            style={{
              border: "none",
              borderColor: "transparent",
              outline: "none",
              caretColor: "transparent",
              width: "100%",
            }}
          />
        </div>
      </React.Fragment>
    );
  }

  render() {
    const mobile =
      window.innerWidth * 0.8 < parseInt(calcularPx(1163).slice(0, -2));
    const styleCampo = {
    };
    let f = new Date();
    const offset = f.getTimezoneOffset();
    f = new Date(f.getTime() - offset * 60 * 1000);
    let fecha = f.toISOString().split("T")[0];
    return (
      <React.Fragment>
        {!this.state.enviado ? (
          <div style={{width: "80%", marginLeft: "auto", marginRight: "auto", display: "flex", flexDirection: "column", alignContent: "center", alignItems: "center"}}>
            <div style={{width: "100%", height: "7vw"}}/>
            <IcoGarantia width={220} height={220}/>
            <div style={{width: "100%", height: "4vw"}}/>
            <h1 className="garantia_titulo">GARANTÍA EXTENDIDA</h1>
            <div style={{width: "100%", height: "3vw"}}/>
            <h2 className="garantia_texto1">¡Registrá tu producto y disfrutá de los beneficios!</h2>
            <p className="garantia_texto2" style={{maxWidth: 680}}>La garantía extendida de Coppens te brinda tranquilidad adicional para tus electrodomésticos, cubriendo reparaciones  y repuestos originales por más tiempo.</p>
            <div style={{width: "100%", height: "3vw"}}/>
            {(!this.state.mostrar || true) ? 
            <div className="garantia_proximamente">
              PROXIMAMENTE
              {<></>/*<button className="garantia_boton" onClick={() => this.setState({mostrar: true})}>Registrar producto</button>*/}
            </div>            
            :
            <div
              className="garantia_recuadro"
            >
              <h1
                className="garantia_recuadro_aclaracion"
              >
                Los campos marcados con{" "}
                <span style={{ color: "#9d1c21" }}>*</span> son obligatorios.
              </h1>
              <div className="garantia_recuadro_titulo">
                  Datos personales
              </div>
              <div style={{display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between", gap: 24}}>
                  <div className="garantia_campo_titulo">
                    Nombre<span style={{ color: "#9d1c21" }}>*</span>
                    <input
                      className="garantia_campo"
                      type="text"
                      style={styleCampo}
                      onChange={(event) => {
                        let valido =
                          0 < event.target.value.length &&
                          event.target.value.length <= 20;
                        if (valido) {
                          event.target.style.borderColor = "#cccbcb";
                        } else {
                          event.target.style.borderColor = "#9d1c21";
                        }
                        this.setState({
                          nombre: event.target.value,
                          nombreValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                  </div>
                  <div className="garantia_campo_titulo">
                      Apellido<span style={{ color: "#9d1c21" }}>*</span>
                    <input
                      className="garantia_campo"
                      type="text"
                      style={styleCampo}
                      onChange={(event) => {
                        let valido =
                          0 < event.target.value.length &&
                          event.target.value.length <= 20;
                        if (valido) {
                          event.target.style.borderColor = "#cccbcb";
                        } else {
                          event.target.style.borderColor = "#9d1c21";
                        }
                        this.setState({
                          apellido: event.target.value,
                          apellidoValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                  </div>
              </div>
              <div style={{display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between", gap: 24}}>
                  <div className="garantia_campo_titulo">
                      Email<span style={{ color: "#9d1c21" }}>*</span>
                    <input
                      className="garantia_campo"
                      type="email"
                      style={styleCampo}
                      onChange={(event) => {
                        let valido = this.validarMail(event.target.value);
                        if (valido) {
                          event.target.style.borderColor = "#cccbcb";
                        } else {
                          event.target.style.borderColor = "#9d1c21";
                        }
                        this.setState({
                          email: event.target.value,
                          emailValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                  </div>
                  <div className="garantia_campo_titulo">
                      Celular<span style={{ color: "#9d1c21" }}>*</span>
                    <input
                      className="garantia_campo"
                      type="tel"
                      style={styleCampo}
                      value={this.state.celular}
                      onChange={(event) => {
                        event.target.value = event.target.value.replace(
                          /[^0-9\+\-\s]/g,
                          ""
                        );
                        let value = event.target.value;
                        let valido = 6 < value.length && value.length <= 30;
                        if (valido) {
                          event.target.style.borderColor = "#cccbcb";
                        } else {
                          event.target.style.borderColor = "#9d1c21";
                        }
                        this.setState({
                          celular: value,
                          celularValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                  </div>
              </div>
              <h1 className="garantia_recuadro_titulo">
                  Domicilio
                </h1>
              <div style={{display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between", gap: 24}}>
                  <div className="garantia_campo_titulo">
                      Calle<span style={{ color: "#9d1c21" }}>*</span>
                    <input
                      className="garantia_campo"
                      type="text"
                      style={styleCampo}
                      onChange={(event) => {
                        let valido =
                          0 < event.target.value.length &&
                          event.target.value.length <= 25;
                        if (valido) {
                          event.target.style.borderColor = "#cccbcb";
                        } else {
                          event.target.style.borderColor = "#9d1c21";
                        }
                        this.setState({
                          calle: event.target.value,
                          calleValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                  </div>
                  <div className="garantia_campo_titulo">
                      Número<span style={{ color: "#9d1c21" }}>*</span>
                    <input
                      className="garantia_campo"
                      type="text"
                      pattern="[0-9]*"
                      style={styleCampo}
                      value={this.state.altura}
                      onChange={(event) => {
                        event.target.value = event.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                        let value = event.target.value;
                        let valido = 0 < value.length && value.length <= 6;
                        if (valido) {
                          event.target.style.borderColor = "#cccbcb";
                        } else {
                          event.target.style.borderColor = "#9d1c21";
                        }
                        this.setState({
                          altura: value,
                          alturaValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                  </div>
                  <div className="garantia_campo_titulo">
                    Piso
                    <input
                      className="garantia_campo"
                      type="text"
                      style={styleCampo}
                      onChange={(event) => {
                        let valido = event.target.value.length < 10;
                        if (valido) {
                          event.target.style.borderColor = "#cccbcb";
                        } else {
                          event.target.style.borderColor = "#9d1c21";
                        }
                        this.setState({
                          piso: event.target.value,
                          pisoValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                  </div>
                  <div className="garantia_campo_titulo">
                    Departamento
                    <input
                      className="garantia_campo"
                      type="text"
                      style={styleCampo}
                      onChange={(event) => {
                        let valido = event.target.value.length < 10;
                        if (valido) {
                          event.target.style.borderColor = "#cccbcb";
                        } else {
                          event.target.style.borderColor = "#9d1c21";
                        }
                        this.setState({
                          dpto: event.target.value,
                          dptoValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                  </div>
                  <div className="garantia_campo_titulo">
                      Provincia<span style={{ color: "#9d1c21" }}>*</span>
                    <Select
                      className="garantia_campo"
                      style={{
                        paddingLeft: 10,
                        border: "1px solid #cccbcb",
                        borderRadius: 7,
                        backgroundColor: "#FFFFFF"
                    }}
                      color="#9d1c21"
                      placeholder_desactivado="Provincia"
                      searchable={true}
                      separator={true}
                      noDataLabel={"No hay resultados."}
                      handleKeyDownFn={({ methods, state }) => {
                        let input = document.getElementById("Provincia");
                        input.focus();
                        if (state.values[0]) {
                          methods.clearAll();
                          input.value = "";
                        }
                      }}
                      separatorRenderer={({ methods }) => {
                        this.selectProvMethods = methods;
                      }}
                      dropdownGap={0}
                      labelField={"nombre"}
                      searchBy={"nombre"}
                      valueField={"id"}
                      options={this.state.provincias}
                      onChange={(values) =>
                        this.handleChangeProvincia(values[0])
                      }
                      contentRenderer={this.customContentRenderer}
                      searchFn={this.customSearch}
                    />
                  </div>
                  <div className="garantia_campo_titulo">
                      Localidad<span style={{ color: "#9d1c21" }}>*</span>
                    <Select
                      className="garantia_campo"
                      style={{
                        paddingLeft: 10,
                        border: "1px solid #cccbcb",
                        borderRadius: 7,
                        backgroundColor: "#FFFFFF"
                    }}
                      color="#9d1c21"
                      placeholder_desactivado="Localidad"
                      searchable={true}
                      separator={true}
                      noDataLabel={"No hay resultados."}
                      handleKeyDownFn={({ methods, state }) => {
                        let input = document.getElementById("Localidad");
                        input.focus();
                        if (state.values[0]) {
                          methods.clearAll();
                          input.value = "";
                        }
                      }}
                      separatorRenderer={({ methods }) => {
                        this.selectLocMethods = methods;
                      }}
                      dropdownGap={0}
                      labelField={"nombre"}
                      searchBy={"nombre"}
                      valueField={"id"}
                      options={this.state.localidades}
                      onChange={(values) =>
                        this.handleChangeLocalidad(values[0])
                      }
                      contentRenderer={this.customContentRenderer}
                      searchFn={this.customSearch}
                    />
                  </div>
                  <div className="garantia_campo_titulo">
                      Código postal<span style={{ color: "#9d1c21" }}>*</span>
                    <input
                      className="garantia_campo"
                      type="text"
                      style={styleCampo}
                      onChange={(event) => {
                        let valido =
                          0 < event.target.value.length &&
                          event.target.value.length <= 10;
                        if (valido) {
                          event.target.style.borderColor = "#cccbcb";
                        } else {
                          event.target.style.borderColor = "#9d1c21";
                        }
                        this.setState({
                          codpostal: event.target.value,
                          codpostalValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                  </div>
              </div>

              <h1 className="garantia_recuadro_titulo">
                  Datos del producto
                </h1>
              <div style={{display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between", gap: 24}}>                  
                  <div className="garantia_campo_titulo">
                      Categoría<span style={{ color: "#9d1c21" }}>*</span>
                    <Select
                      className="concurso_campo"
                      style={{
                        paddingLeft: 10,
                        border: "1px solid #cccbcb",
                        borderRadius: 7,
                        backgroundColor: "#FFFFFF"
                    }}
                      color="#9d1c21"
                      placeholder_desactivado="Categoría"
                      searchable={true}
                      separator={true}
                      noDataLabel={"No hay resultados."}
                      handleKeyDownFn={({ methods, state }) => {
                        let input = document.getElementById("Categoría");
                        input.focus();
                        if (state.values[0]) {
                          methods.clearAll();
                          input.value = "";
                        }
                      }}
                      separatorRenderer={({ methods }) => {
                        this.selectCatMethods = methods;
                      }}
                      dropdownGap={0}
                      labelField={"nombre"}
                      searchBy={"nombre"}
                      valueField={"idcategoria"}
                      options={this.state.categorias}
                      onChange={(values) =>
                        this.handleChangeCategoria(values[0])
                      }
                      contentRenderer={this.customContentRenderer}
                      searchFn={this.customSearch}
                    />
                  </div>
                  <div className="garantia_campo_titulo">
                      Modelo<span style={{ color: "#9d1c21" }}>*</span>
                    <Select
                      className="concurso_campo"
                      style={{
                        paddingLeft: 10,
                        border: "1px solid #cccbcb",
                        borderRadius: 7,
                        backgroundColor: "#FFFFFF"
                    }}
                      color="#9d1c21"
                      placeholder_desactivado="Modelo"
                      searchable={true}
                      separator={true}
                      noDataLabel={"No hay resultados."}
                      handleKeyDownFn={({ methods, state }) => {
                        let input = document.getElementById("Modelo");
                        input.focus();
                        if (state.values[0]) {
                          methods.clearAll();
                          input.value = "";
                        }
                      }}
                      separatorRenderer={({ methods }) => {
                        this.selectModMethods = methods;
                      }}
                      dropdownGap={0}
                      labelField={"modelo"}
                      searchBy={"modelo"}
                      valueField={"id"}
                      options={this.state.modelos}
                      onChange={(values) => this.handleChangeModelo(values[0])}
                      contentRenderer={this.customContentRenderer}
                      searchFn={this.customSearch}
                    />
                  </div>
                  <div className="garantia_campo_titulo">
                      Nº de serie<span style={{ color: "#9d1c21" }}>*</span>
                    <input
                      className="garantia_campo"
                      type="text"
                      style={styleCampo}
                      onChange={(event) => {
                        let valido =
                          0 < event.target.value.length &&
                          event.target.value.length <= 30;
                        if (valido) {
                          event.target.style.borderColor = "#cccbcb";
                        } else {
                          event.target.style.borderColor = "#9d1c21";
                        }
                        this.setState({
                          serie: event.target.value,
                          serieValido: valido,
                          error: null,
                        });
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          document.getElementById("botonEnviar").click();
                        }
                      }}
                    />
                  </div>
                  
              </div>
              <div style={{display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between", gap: 24}}>
                <div className="garantia_campo_titulo">
                      Fecha de compra<span style={{ color: "#9d1c21" }}>*</span>
                    <input
                      type="date"
                      className="garantia_campo"
                      style={styleCampo}
                      max={fecha}
                      onChange={(event) => {
                        let fn = new Date(event.target.value);
                        let valido = fn <= f;
                        if (valido) {
                          event.target.style.borderColor = "#cccbcb";
                        } else {
                          event.target.style.borderColor = "#9d1c21";
                        }
                        this.setState({
                          fecha_compra: event.target.value,
                          fecha_compraValido: valido,
                          error: null,
                        });
                      }}
                    />
                </div>
                <div className="garantia_campo_titulo">
                    Comercio<span style={{ color: "#9d1c21" }}>*</span>
                  <input
                    className="garantia_campo"
                    type="text"
                    style={styleCampo}
                    onChange={(event) => {
                      let valido =
                        0 < event.target.value.length &&
                        event.target.value.length <= 30;
                      if (valido) {
                        event.target.style.borderColor = "#cccbcb";
                      } else {
                        event.target.style.borderColor = "#9d1c21";
                      }
                      this.setState({
                        comercio: event.target.value,
                        comercioValido: valido,
                        error: null,
                      });
                    }}
                    onKeyUp={(event) => {
                      if (event.key === "Enter") {
                        document.getElementById("botonEnviar").click();
                      }
                    }}
                  />
                </div>
              </div>    
              <div style={{marginTop: 48,width: "100%", display: "flex", justifyContent: "center"}}>
              <button
                id="botonEnviar"
                className="garantia_recuadro_boton"
                disabled={
                  !(
                    this.state.alturaValido &&
                    this.state.apellidoValido &&
                    this.state.calleValido &&
                    this.state.categoriaValido &&
                    this.state.celularValido &&
                    this.state.codpostalValido &&
                    this.state.comercioValido &&
                    this.state.dptoValido &&
                    this.state.fecha_compraValido &&
                    this.state.localidadValido &&
                    this.state.emailValido &&
                    this.state.modeloValido &&
                    this.state.nombreValido &&
                    this.state.serieValido &&
                    this.state.pisoValido &&
                    this.state.provinciaValido &&
                    this.state.error == null &&
                    !this.state.enviando
                  )
                }
                onClick={() => this.registrar()}
              >
                Enviar
              </button>
              </div>
            </div>
            }
          </div>
        ) : (
          <Navigate to="/registrar-producto/exito" replace={true} />
        )}

        <div style={{width: "100%", height: "7vw"}}/>
      </React.Fragment>
    );
  }
}

export default RegistrarProducto;
