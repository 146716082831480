import React, { Component, useEffect } from "react";
import { useLocation, Link, useParams, useNavigate } from "react-router-dom";
import { ReactComponent as IcoTickMail } from "../../res/iconos/tickFiltro.svg";
import { ReactComponent as IcoTick } from "../../res/iconos/tick.svg";

function withRouterAndNavigate(Component) {
    function ComponentWithRouterAndNavigate(props) {
      let params = useParams();
      let navigate = useNavigate();
      let location = useLocation();
      return (
        <Component
          {...props}
          params={params}
          navigate={navigate}
          location={location}
        />
      );
    }
    return ComponentWithRouterAndNavigate;
  }

class Comprar extends Component {
    state = {}

    componentDidMount() {
        window.scrollTo(0, 0);
        this.get();
    }

    get() {
        let datos = this.props.params;
        let newState = {...this.state};
        for (let i in newState) {
          newState[i] = "";
        }
        newState.imagenes = [];
        this.setState(newState, () => {
          fetch(`/api/productos/detalle?categoria=${datos.categoria}&url=${datos.url}`)
          .then((res) => res.json())
          .then(async (data) => {
            this.setState({...datos, ...data})
        })
        })
    }

    componentDidUpdate(prevProps) {
        let datos = this.props.params;
        if (prevProps.params.categoria !== datos.categoria || prevProps.params.url !== datos.url) {
          window.scrollTo(0, 0);
          this.get();
        }
    }

    async enviar() {
        const res = await fetch("/api/productos/mail", {
            method: "post",
            body: JSON.stringify({
              tabla: this.state.categoria,
              idProducto: this.state.id,
              mail: this.state.email
            }),
            headers: { "Content-Type": "application/json" },
          });
        if (res.status == 200) {
            this.setState({enviado: true})
        }
    }

    validarMail(email) {
        const emailRegex =
          /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
        if (!email) return false;
    
        if (email.length > 254) return false;
    
        let valid = emailRegex.test(email);
        if (!valid) return false;
    
        var parts = email.split("@");
        if (parts[0].length > 64) return false;
    
        var domainParts = parts[1].split(".");
        if (
          domainParts.some(function (part) {
            return part.length > 63;
          })
        )
          return false;
    
        return true;
    }

    state = {  } 
    render() { 
        const styleCampo = {
        };
        return (
        <div style={{width: "80%", maxWidth: 873, marginLeft: "auto", marginRight: "auto", display: "flex", flexDirection: "column", alignContent: "center", alignItems: "center"}}>
            <div style={{width: "100%", height: 100}}/>
            <IcoTick width={121} height={121}/>
            <div style={{width: "100%", height: 40}}/>
            <h1 className="comprar_titulo">
            ¡Gracias por tu interés!
            </h1>
            <div style={{width: "100%", height: 30}}/>
            <h2 className="comprar_mensaje1">
                {"Este producto aún"}<span className="gracias_mensaje1_rojo">{" no está disponible"}</span>{" pero pronto lo estará."}
            </h2>

            <div style={{width: "100%", height: 40}}/>
            <h3 className="comprar_mensaje2">
                Dejanos tu correo y te avisaremos cuando haya stock.
            </h3>
            <div className="comprar_container_mail">
                <input
                    className="comprar_mail"
                    type="email"
                    style={styleCampo}
                    onChange={(event) => {
                    let valido = this.validarMail(event.target.value);
                    if (valido) {
                        event.target.style.borderColor = "#cccbcb";
                    } else {
                        event.target.style.borderColor = "#9d1c21";
                    }
                    this.setState({
                        email: event.target.value,
                        emailValido: valido,
                        error: null,
                    });
                    }}
                    onKeyUp={(event) => {
                    if (event.key === "Enter") {
                        document.getElementById("botonEnviar").click();
                    }
                    }}
                />
                <button className={"comprar_boton_mail" + (this.state.enviado?"_enviado":"")} disabled={!this.state.id || !this.state.emailValido || this.state.enviado} onClick={() => this.enviar()}>
                    {this.state.enviado
                    ?
                    <IcoTickMail height={30} width={30}/>
                    :
                    "Enviar"
                    }
                </button>
                
            </div>

            <div style={{width: "100%", height: 30}}/>
            <div className="comprar_container_final">
                <h4 className="comprar_mensaje3">
                    Mientras tanto, puedes seguir explorando nuestro catálogo de productos.
                </h4>
                <Link to="/">
                    <button className="comprar_boton_home">
                        Volver a la home
                    </button>
                </Link>
            </div>

            <div style={{width: "100%", height: "7vw"}}/>
        </div>
        );
    }
}
 
export default withRouterAndNavigate(Comprar);
