import React, { useEffect } from "react";
import { Card, CardContent } from "@mui/material";
import { Title } from "react-admin";
import { useNavigate } from "react-router-dom";

const ErrorAuth = () => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => navigate("/"), 3000);
  }, []);

  return (
    <Card>
      <Title title="Sesión expirada" />
      <CardContent>
        <div
          style={{
            textAlign: "center",
            marginLeft: "10%",
            marginRight: "10%",
          }}
        >
          <h1 className="tituloError">Sesión expirada</h1>
          <h1 className="textoError">Debe ingresar nuevamente.</h1>
          <h1 className="textoError">Redirigiendo a la página principal...</h1>
        </div>
      </CardContent>
    </Card>
  );
};

export default ErrorAuth;
